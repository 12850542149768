<template>
  <div class="w-100">
    <h1>Searches</h1>

    <div class="row mt-4">
      <div class="col-lg-3 mb-3">
        <label for="date-datepicker">From:</label>
        <date-picker
            v-model="from"
            id="date-datepicker"
            locale="en"
            format="YYYY-MM-DD"
            clearable
            color="green"
        />
      </div>
      <div class="col-lg-3">
        <label for="date-datepicker">To:</label>
        <date-picker
            v-model="to"
            id="date-datepicker"
            locale="en"
            format="YYYY-MM-DD"
            clearable
            color="#f42f4b"
        />
      </div>
      <div class="col-lg-2">
        <label class="d-block" style="visibility: hidden">Filter</label>
        <button class="btn btn-primary" @click="loadItems">
          <i class="fas fa-search"></i>
        </button>

        <button
            class="btn btn-secondary ml-2"
            @click="exportToCSV"
            v-if="items"
            title="Export to csv"
        >
          <i class="fas fa-cloud-download-alt"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">phrase</th>
            <th scope="col">The number of times searched</th>
          </tr>
        </thead>
        <tbody>
        <template v-if="items.length > 0">
          <tr v-for="item in items" v-bind:key="item.phrase">
            <td>{{ item.phrase }}</td>
            <td>{{ item.count }}</td>
          </tr>
        </template>

          <tr v-else>
            <td colspan="2" align="center">Please select your date to view the data</td>
          </tr>
        </tbody>
      </table>
    </div>

<!--    <nav>-->
<!--      <ul class="pagination">-->
<!--        <li-->
<!--          class="page-item"-->
<!--          v-bind:class="{ disabled: page == 1 }"-->
<!--          @click="previousPage()"-->
<!--        >-->
<!--          <a class="page-link" href="#" tabindex="-1">Previous</a>-->
<!--        </li>-->
<!--        <li-->
<!--          class="page-item"-->
<!--          v-bind:class="{ disabled: page == lastPage }"-->
<!--          @click="nextPage()"-->
<!--        >-->
<!--          <a class="page-link" href="#">Next</a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </nav>-->
  </div>
</template>

<script>

import {config} from "@/helpers";

export default {
  name: "Searches",
  data() {
    return {
      url: `${config("BACKEND_ROOT")}/admin/searches`,
      items: [],
      page: 1,
      lastPage: 1,
      from: null,
      to: null,
    };
  },

  methods: {
    loadItems() {
      let myData = { from: this.from, to: this.to };

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        // this.page = response.data.meta.current_page;
        // this.lastPage = response.data.meta.last_page;
      });
    },
    exportToCSV(){
      let myData = { from: this.from, to: this.to };
      axios.get(this.url + "/export",{ params: myData }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "searches.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>