<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">Gift #{{ gift.id }}</h1>

      <div class="mt-2">
        <span class="text-secondary">
          <i class="fa fa-box"></i> {{ gift.title }}
        </span>

        <span class="text-secondary ml-4">
          <i class="fas fa-map-marker-alt mr-1"></i> {{ gift.city }}
        </span>

        <span class="text-secondary ml-4">
          <i class="fa fa-bars mr-1"></i> {{ gift.type }}
        </span>

        <span
          class="text-secondary ml-4"
          v-if="gift.meta && gift.meta.sub_total"
          @click="changePrice()"
        >
          <i class="fa fa-coins mr-1"></i> {{ gift.meta.sub_total }} AED
        </span>

        <span
          class="text-secondary ml-4"
          v-if="gift.meta && gift.meta.touched"
          title="It means that customer has used this gift partly or completely."
        >
          <i class="fa fa-pen"></i> Touched
        </span>

        <span class="float-right" v-html="statusBadge"></span>
      </div>

      <hr />

      <div class="row text-center pl-4 pr-4 mt-5 mb-4" style="font-size: 14px">
        <div class="col-lg-3">
          <div>
            <strong>Customer name</strong><br />{{ gift.customer_name }}
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Customer country</strong><br />{{ gift.customer_country }}
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Purchase date</strong><br />{{
              formatDate(gift.created_at)
            }}
          </div>
        </div>

        <div class="col-lg-3">
          <div id="tooltip-target-1">
            <strong>Expiration date</strong><br />
            {{ gift.expires_at }}
          </div>
          <b-tooltip
            target="tooltip-target-1"
            triggers="hover"
            placement="bottom"
          >
            <div v-html="giftExpirationHistory"></div>
          </b-tooltip>
        </div>
      </div>

      <div class="row text-center pl-4 pr-4 mt-5 mb-4" style="font-size: 14px">
        <div class="col-lg-3 mb-3">
          <div>
            <strong>Refcode / Password</strong><br />{{ gift.ref_code }} /
            {{ gift.password }}
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Recipient email</strong><br />
            <span v-text="gift.details.email ? gift.details.email : '-'"></span>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Passengers</strong><br />
            <span v-text="passengers"></span>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Cart id</strong><br />
            <span
              @click.prevent="$router.push(`/carts/${gift.meta.cart_id}`)"
              style="color: #f42f4b; cursor: pointer"
              >{{ gift.meta.cart_id }}</span
            >
          </div>
        </div>

        <div class="col-lg-3" v-if="gift.meta.invoice_id">
          <div>
            <strong>Invoice code</strong><br />
            <span
              @click.prevent="$router.push(`/invoices/${gift.meta.invoice_id}`)"
              style="color: #f42f4b; cursor: pointer"
              >{{ "DD00" + gift.meta.invoice_code }}</span
            >
          </div>
        </div>

        <div class="col-lg-3" v-if="gift.meta.order_id">
          <div>
            <strong>Order id</strong><br />
            <span
              @click.prevent="$router.push(`/orders/${gift.meta.order_id}`)"
              style="color: #f42f4b; cursor: pointer"
              >{{ gift.meta.order_id }}</span
            >
          </div>
        </div>
      </div>

      <div id="card" class="row text-center border p-4 mt-5">
        <div class="col-lg-6">
          <strong>From:</strong> {{ gift.details.from }}
        </div>
        <div class="col-lg-6"><strong>To:</strong> {{ gift.details.to }}</div>
        <div class="col-lg-12 mt-2">{{ gift.details.message }}</div>
      </div>
      <button class="btn btn-sm btn-light" @click="print()">
        <i class="fa fa-print"></i>
      </button>

      <div v-if="gift.type == 'giftbox'" class="row mt-2">
        <div class="col-lg-4">
          <strong>Delivery date:</strong> {{ gift.details.delivery_date }}<br />
          <strong>Phone number:</strong>
          {{ `${gift.details.delivery_phone_number}` }}
        </div>
        <div class="col-lg-8">
          <strong>Delivery address:</strong>
          {{
            `${gift.details.delivery_address.city_title}: ${gift.details.delivery_address.address} (${gift.details.delivery_address.post_code})`
          }}
        </div>
      </div>

      <div v-show="gift.comments" class="mt-3">
        Comments: {{ gift.comments }}
      </div>
    </div>

    <div id="edit-form" class="w-50 pl-2 pb-3">
      <button
        @click.prevent="showExchangeModal()"
        class="btn btn-danger d-inline-block mr-3"
        v-if="gift && gift.status == 'Open'"
      >
        Exchange the gift
      </button>

      <button
        @click.prevent="$modal.show('renew-modal')"
        class="btn btn-success d-inline-block mr-3"
        v-if="gift && !['Dated', 'Canceled', 'Exchanged'].includes(gift.status)"
      >
        Extend gift expiration
      </button>

      <button
        @click.prevent="cancel()"
        class="btn btn-warning d-inline-block mr-3"
        v-if="gift && gift.status == 'Open'"
      >
        Cancel
      </button>

      <button
        @click.prevent="$modal.show('comments-modal')"
        class="btn btn-primary d-inline-block"
      >
        Edit comments
      </button>
    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>

    <modal name="exchange-modal" height="auto" v-if="loaded">
      <div class="p-4">
        <button
          @click="$modal.hide('exchange-modal')"
          class="modal-close-button"
        >
          ❌
        </button>
        <div class="h3 mb-3">Exchange the gift</div>
        <form action="">
          <h6>Deposit the credit into wallet of...?</h6>
          <div class="form-check">
            <input
              name="email"
              v-model="exchangeForm.email"
              class="form-check-input"
              type="radio"
              id="customer_option"
              :value="gift.customer_email"
            />
            <label class="form-check-label" for="customer_option">
              Customer: {{ gift.customer_email }}
            </label>
          </div>

          <div class="form-check mt-1 mb-3">
            <input
              name="email"
              v-model="exchangeForm.email"
              class="form-check-input"
              type="radio"
              id="recipient_option"
              :value="gift.details.email"
              :disabled="!gift.details.email"
            />
            <label class="form-check-label" for="recipient_option">
              Recipient: {{ gift.details.email || "-" }}
            </label>
          </div>

          <h6>or someone else:</h6>
          <search-box
            @typing="searchUser"
            @selected="selectSearchedUser"
            :results="exchangeForm.searchedEmails"
            placeholder="Search by email, phone or name"
            class="w-100"
          ></search-box>

          <div class="form-check mt-2 mb-2" v-if="exchangeForm.searchedEmail">
            <input
              name="email"
              v-model="exchangeForm.email"
              class="form-check-input"
              type="radio"
              id="another_user_option"
              :value="exchangeForm.searchedEmail"
            />
            <label class="form-check-label" for="another_user_option">
              {{ exchangeForm.searchedEmail }}
            </label>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in exchangeForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>
          <button
            class="btn btn-danger mt-2 w-100"
            @click.prevent="exchange()"
            :disabled="exchangeForm.email.length == 0"
          >
            Confirm
          </button>
        </form>
      </div>
    </modal>

    <modal name="renew-modal" height="auto" v-if="loaded">
      <renew-modal :giftId="gift.id" @done="loadData()"></renew-modal>
    </modal>

    <modal name="comments-modal" height="auto" v-if="loaded">
      <comments-modal
        :giftId="gift.id"
        :giftComments="gift.comments"
        @done="loadData()"
      ></comments-modal>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { config } from "../helpers";
import SearchBox from "../components/SearchBox.vue";
import RenewModalVue from "../components/ViewComponents/Gifts/RenewModal.vue";
import GiftCommentsModalVue from "../components/ViewComponents/Gifts/GiftCommentsModal.vue";

export default {
  name: "Gift",
  components: {
    "search-box": SearchBox,
    "renew-modal": RenewModalVue,
    "comments-modal": GiftCommentsModalVue,
  },
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      gift: null,

      exchangeForm: {
        email: "",
        searchedUsers: [],
        searchedEmail: "",
        errors: [],
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/gifts";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.gift = response.data.data;
        this.loaded = true;
      });
    },

    showExchangeModal() {
      this.exchangeForm = {
        email: "",
        searchedEmails: [],
        searchedEmail: "",
        errors: [],
      };

      this.$modal.show("exchange-modal");
    },

    searchUser: _.debounce(function (q) {
      axios
        .get(`${config("BACKEND_ROOT")}/admin/users?q=${q}`)
        .then((response) => {
          let emails = [];
          for (let user of response.data.data) {
            emails.push(user.email);
          }

          this.exchangeForm.searchedEmails = emails;
        });
    }, 500),

    selectSearchedUser(email) {
      this.exchangeForm.searchedEmail = this.exchangeForm.email = email;
    },

    exchange() {
      if (
        !confirm(
          `It deposits ${this.gift.meta.sub_total} into account of ${this.exchangeForm.email}?`
        )
      ) {
        return;
      }

      this.loaded = false;
      axios
        .post(`${this.url}/${this.id}/exchange`, {
          email: this.exchangeForm.email,
        })
        .then((response) => {
          this.gift = response.data.data;
          this.loaded = true;
        })
        .catch((error) => {
          alert(error.response.data?.meta?.message);
          this.loaded = true;
        });
    },

    print() {
      var headstr = "<html><head><title></title></head><body>";
      var footstr = "</body>";
      var newstr = document.all.item("card").innerHTML;
      var oldstr = document.body.innerHTML;
      document.body.innerHTML = headstr + newstr + footstr;
      window.print();
      document.body.innerHTML = oldstr;
      return false;
    },

    cancel() {
      if (!confirm("Do you want to cancel this gift?")) {
        return;
      }

      if (!confirm("It'll be disabled forever, are you sure?")) {
        return;
      }

      this.loaded = false;

      axios
        .post(`${this.url}/${this.id}/cancel`)
        .then((response) => {
          this.gift = response.data.data;
          this.loaded = true;
        })
        .catch((error) => {
          alert(error.response.data?.meta?.message);
          this.loaded = true;
        });
    },

    changePrice() {
      let newAmount = prompt("Enter the new sub total...");

      if (newAmount == null) {
        return;
      }

      if (!/^\d+(.\d+)?$/.test(newAmount) || newAmount < 0) {
        return alert("Please enter a valid number!");
      }

      this.loaded = false;

      axios
        .patch(`${this.url}/${this.id}`, {
          field: "meta->sub_total",
          value: newAmount,
        })
        .then((response) => {
          this.gift = response.data.data;
          this.loaded = true;
        })
        .catch((error) => {
          alert(error.response.data?.meta?.message);
          this.loaded = true;
        });
    },

    formatDate(date) {
      if (!date) return null;

      if (typeof date == "string") {
        date = new Date(date);
      }

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },

  computed: {
    statusBadge() {
      let giftStatus = this.gift.status;

      let classes = {
        Dated: "badge-success",
        Exchanged: "badge-warning",
        Canceled: "badge-warning",
        Expired: "badge-danger",
        Open: "badge-primary",
      };

      return `<div class="badge ${classes[giftStatus]}">${giftStatus}</div>`;
    },

    giftExpirationHistory() {
      if (
        !this.gift.meta?.expiration_history ||
        !"expiration_history" in this.gift.meta
      ) {
        return "No history";
      }

      let output = "";
      for (let item of this.gift.meta.expiration_history) {
        output += `${item.old_expiration_date} > ${
          item.new_expiration_date
        }<br>${item.cost} AED${
          item.payment_method ? " by " + item.payment_method : ""
        }<br>-------------------<br>`;
      }

      return output;
    },

    passengers() {
      const passengers = this.gift.details.passengers;

      if (! passengers) {
        return "-";
      }

      if (Number.isInteger(passengers)) {
        return passengers;
      }

      let output = [];
      for (let passenger of passengers) {
        output.push(`${passenger.title} x ${passenger.count}`);
      }

      return output.join(" | ");
    },
  },
};
</script>

<style scoped>
#edit-form {
  position: fixed;
  bottom: 0;
}
</style>