<template>
  <div class="home">
    <div v-if="loaded">
      <div
        class="row mt-4 pt-4 pb-4 mr-3 ml-3"
        style="background: #f7f5f5; border-radius: 12px"
      >
        <div class="col-4">
          <div class="dashboard__stat">
            <div
              class="dashboard__stat__value"
              :class="
                info.stats.today_sales == '0.00'
                  ? 'text-danger'
                  : 'text-success'
              "
            >
              {{ info.stats.today_sales }}
            </div>
            <div class="dashboard__stat__title">Today Sales</div>
          </div>
        </div>
        <div class="col-4">
          <div class="dashboard__stat">
            <div class="dashboard__stat__value">{{ info.stats.users }}</div>
            <div class="dashboard__stat__title">Users</div>
          </div>
        </div>
        <div class="col-4">
          <div class="dashboard__stat">
            <div class="dashboard__stat__value">
              {{ info.stats.subscribers }}
            </div>
            <div class="dashboard__stat__title">Subscribers</div>
          </div>
        </div>
      </div>

      <div class="row mt-5 mr-3 ml-3">
        <b-card-group class="w-100">
          <b-card header="Graphical Statistic">
            <div v-if="chartLoaded" class="row">
              <div  class="w-100" id="chart">
                <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </b-card>
        </b-card-group>
      </div>

      <div class="row mt-5 mr-3 ml-3">
        <b-card-group class="w-100">
          <b-card header="Orders">
            <div class="row">
              <div class="col-3">
                <button
                  class="btn btn-outline-danger w-100 dashboard-big-button"
                  @click="$router.push(`/orders`)"
                >
                  Booked List
                </button>
              </div>
              <div class="col-3">
                <button
                  class="btn btn-outline-danger w-100 dashboard-big-button"
                  @click="$router.push(`/gifts`)"
                >
                  Gifts List
                </button>
              </div>
              <div class="col-3">
                <button
                  class="btn btn-outline-danger w-100 dashboard-big-button"
                  @click="$router.push(`/carts`)"
                >
                  Carts List
                </button>
              </div>
              <div class="col-3">
                <button
                  class="btn btn-outline-danger w-100 dashboard-big-button"
                  @click="$router.push(`/users`)"
                >
                  Customers List
                </button>
              </div>
            </div>
          </b-card>
        </b-card-group>
      </div>

      <div class="row mt-5 mr-3 ml-3 mb-3">
        <b-card-group class="w-100">
          <b-card header="Quick Search">
            <div class="row">
              <div class="col-6">
                <label for="userSearchInput" class="mb-0"
                  >Customer Quick Search:</label
                >
                <p>You can search by email, name and phone number...</p>
                <input
                  v-model="userSearchInput"
                  class="form-control"
                  id="userSearchInput"
                  autocomplete="off"
                />
                <button class="btn btn-dark w-100 mt-3" @click="searchUser">
                  Search Customer
                </button>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="orderSearchInput" class="w-100"
                        >Order id:</label
                      >
                      <input
                        class="form-control w-50 d-inline mr-3"
                        id="orderSearchInput"
                        ref="orderSearchInput"
                        autocomplete="off"
                      />
                      <button
                        class="btn btn-dark d-inline"
                        @click="
                          $router.push(
                            `/orders/${$refs.orderSearchInput.value}`
                          )
                        "
                      >
                        Go to
                      </button>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="cartSearchInput" class="w-100"
                        >Cart id:</label
                      >
                      <input
                        class="form-control w-50 d-inline mr-3"
                        id="cartSearchInput"
                        ref="cartSearchInput"
                        autocomplete="off"
                      />
                      <button
                        class="btn btn-dark d-inline"
                        @click="
                          $router.push(`/carts/${$refs.cartSearchInput.value}`)
                        "
                      >
                        Go to
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="GiftRefCodeSearchInput" class="w-100"
                        >Gift refcode:</label
                      >
                      <input
                        class="form-control w-50 d-inline mr-3"
                        id="GiftRefCodeSearchInput"
                        ref="GiftRefCodeSearchInput"
                        autocomplete="off"
                      />
                      <button
                        class="btn btn-dark d-inline"
                        @click="
                          searchGiftByRefCode($refs.GiftRefCodeSearchInput.value)
                        "
                      >
                        Go to
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-card-group>
      </div>
    </div>

    <div v-if="!loaded">
      <div
        class="row mt-4 pt-4 pb-4 mr-3 ml-3"
        style="background: #f7f5f5; border-radius: 12px"
      >
        <div class="col-4 text-center">
          <b-skeleton
            width="140px"
            height="140px"
            type="avatar"
            class="d-inline-block"
          ></b-skeleton>
          <b-skeleton
            width="110px"
            height="30px"
            style="margin: 11px auto"
          ></b-skeleton>
        </div>
        <div class="col-4 text-center">
          <b-skeleton
            width="140px"
            height="140px"
            type="avatar"
            class="d-inline-block"
          ></b-skeleton>
          <b-skeleton
            width="110px"
            height="30px"
            style="margin: 11px auto"
          ></b-skeleton>
        </div>
        <div class="col-4 text-center">
          <b-skeleton
            width="140px"
            height="140px"
            type="avatar"
            class="d-inline-block"
          ></b-skeleton>
          <b-skeleton
            width="110px"
            height="30px"
            style="margin: 11px auto"
          ></b-skeleton>
        </div>
      </div>

      <div class="row mt-5 mr-3 ml-3">
        <b-skeleton width="100%" height="143px"></b-skeleton>
      </div>

      <div class="row mt-5 mr-3 ml-3 mb-3">
        <b-skeleton width="100%" height="265px"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";
import moment from "moment";

export default {
  name: "Home",
  data() {
    return {
      loaded: false,
      chartLoaded: false,

      info: [],
      userSearchInput: "",

      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false
          }
        },
        bar: {
          horizontal: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Daily Sell Statistic',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: []
        },
      },
    };
  },

  mounted() {
    this.loadInfo();
    this.getStatistic()
  },

  methods: {
    loadInfo() {
      axios
        .get(config("BACKEND_ROOT") + "/admin/dashboard-info")
        .then((response) => {
          this.info = response.data;
          this.loaded = true;
        });
    },
    getStatistic() {
      axios
          .get(config("BACKEND_ROOT") + "/admin/statistic/sell")
          .then((response) => {
            this.chartOptions.xaxis.categories = response.data.data.days;
            // this.chartOptions.labels = response.data.data.days;
            this.series = response.data.data.series;
            this.chartLoaded = true;
          });
    },

    searchUser() {
      this.$router.push({
        name: "users",
        params: { q: this.userSearchInput },
      });
    },

    searchGiftByRefCode(refCode) {
      axios
        .get(config("BACKEND_ROOT") + `/admin/gifts?ref_code=${refCode}`)
        .then((response) => {
          let data = response.data.data;

          if (data.length == 1) {
            this.$router.push(`/gifts/${data[0].id}`)
          } else {
            alert('Not found!');
          }
        });
    },
  },
};
</script>

<style scoped>
.dashboard__stat {
  text-align: center;
}
.dashboard__stat__value {
  height: 140px;
  width: 140px;
  color: #333;
  border: #e8e8e8 8px solid;
  border-radius: 50%;
  font-weight: bold;
  font-size: 15px;
  padding-top: 53px;
  margin: 0 auto;
}
.dashboard__stat__title {
  display: inline-block;
  background: #f42f4b;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  padding: 3px 8px;
  margin-top: 15px;
}

.dashboard-big-button {
  height: 52px;
  border-radius: 14px;
}
</style>
