<template>
  <div class="w-100">
    <h1>Gifts List</h1>

    <div class="row">
      <div class="col-lg-12 mb-3 mt-2">
        <input
          placeholder="Search..."
          v-model="inputFilters.q"
          class="form-control"
          v-on:keyup.enter="filter"
        />
      </div>
      <div class="col-lg-4">
        <label for="purchase-datepicker">Purchase Date:</label>
        <date-picker
          v-model="inputFilters.purchase_date"
          id="purchase-datepicker"
          locale="en"
          format="YYYY-MM-DD"
          range
          clearable
          color="#f42f4b"
        />
      </div>
      <div class="col-lg-3">
        <label for="type">Gift type:</label>
        <select v-model="inputFilters.type" id="type" class="form-control">
          <option value="evoucher">E-Voucher</option>
          <option value="giftbox">Giftbox</option>
        </select>
      </div>
      <div class="col-lg-3">
        <label for="status">Status:</label>
        <select v-model="inputFilters.status" id="status" class="form-control">
          <option value="1">Not used</option>
          <option value="0">Used</option>
          <option value="2">Exchanged</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label class="d-block" style="visibility: hidden">Filter</label>
        <button class="btn btn-primary" @click="filter">
          <i class="fas fa-search"></i>
        </button>
        <button
          class="btn btn-secondary ml-2"
          @click="exportToCSV"
          v-if="items"
          title="Export to csv"
        >
          <i class="fas fa-cloud-download-alt"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">Gift ID</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Country</th>
            <th scope="col">Experience Name<br />City</th>
            <th scope="col">Purchase Date</th>
            <th scope="col">Type</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <router-link tag="tr" :to="{ name: 'gift.show', params: {id: gift.id}}" v-for="gift in items" v-bind:key="gift.id" style="cursor:pointer;">
            <td>{{ gift.id }}</td>
            <td>{{ gift.customer_name }}</td>
            <td>{{ gift.customer_country }}</td>
            <td>
              {{ gift.title }}<br />
              {{ gift.city }}
            </td>
            <td>{{ formatDate(gift.created_at) }}</td>
            <td>{{ gift.type }}</td>
            <td v-html="getGiftStatus(gift.status)"></td>
          </router-link>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  name: "Gifts",
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: null,
        type: null,
        status: null,
        purchase_date: [],
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      totalItems: 0,
      perPage: 0,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/gifts";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = { ...myData, ...filters };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      if (this.inputFilters.status) {
        filters.status = this.inputFilters.status;
      }

      if (this.inputFilters.type) {
        filters.gift_format = this.inputFilters.type;
      }

      if (this.inputFilters.purchase_date[0]) {
        filters.purchase_date = this.inputFilters.purchase_date;
      }

      this.filters = filters;

      this.loadData();
    },

    exportToCSV() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = { ...myData, ...filters };
      }

      axios.get(this.url + "/export", { params: myData }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "gifts.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },

    formatDate(date) {
      if (! date) return null;

      date = new Date(date);
      
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getGiftStatus(status) {
      let classes = {
        "Dated": "badge-success",
        "Exchanged": "badge-warning",
        "Canceled": "badge-warning",
        "Expired": "badge-danger",
        "Open": "badge-primary"
      };

      return `<div class="badge ${classes[status]}">${status}</div>`;
    },
  },

  watch: {
    page: function (val) {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>