<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">Invoice {{ `DD00${invoice.code}` }}</h1>
      <div class="mt-2">
        <span class="text-secondary">
          <i class="fa fa-calendar mr-1"></i>
          {{ formatDate(invoice.created_at) }}
        </span>

        <span class="text-secondary ml-4" title="Provider">
          <i class="fa fa-home mr-1"></i>
          {{ invoice.provider }}
        </span>

        <span class="text-secondary ml-4" title="Buyer type">
          <i class="fa fa-user mr-1"></i>
          {{ invoice.buyer_type }}
        </span>

        <span
          class="text-secondary ml-4"
          title="Payment reference"
          v-if="invoice.cart?.meta.reference"
        >
          <i class="fa fa-receipt mr-1"></i>
          {{ invoice.cart?.meta.reference.tap_id || invoice.cart?.meta.reference.track }}
        </span>
      </div>

      <hr />

      <div class="row text-center pl-4 pr-4 mt-5 mb-5">
        <div class="col-lg-3">
          <div>
            <strong>Invoice Code</strong>
            <br />
            <div>{{ `DD00${invoice.code}` }}</div>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Cart ID</strong>
            <br />
            <div
              @click.prevent="$router.push(`/carts/${invoice.cart_id}`)"
              style="color: #f42f4b; cursor: pointer"
            >
              {{ invoice.cart_id }}
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Customer Name</strong>
            <br />
            <div
              @click.prevent="
                invoice.cart?.user.id
                  ? $router.push(`/users/${invoice.cart?.user.id}`)
                  : ''
              "
              :style="
                invoice.cart?.user.id
                  ? 'color: #f42f4b; cursor: pointer'
                  : ''
              "
            >
              {{ invoice.customer_name}}
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Payable</strong>
            <br />
            <div>
              {{ invoice.payable.amount + " " + invoice.payable.currency.code }}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="table-responsive">
        <table class="table mt-2">
          <thead class="thead-light">
            <tr>
              <th scope="col">OP Invoice ID</th>
              <th scope="col">Title</th>
              <th scope="col">Gift ID</th>
              <th scope="col">Order ID</th>
              <th scope="col">State</th>
              <th scope="col">Status</th>
              <th scope="col">Purchase Price</th>
              <th scope="col">Selling Price</th>
              <th scope="col">Credit paid</th>
              <th scope="col">Payment Method</th>
              <th scope="col">Supplier</th>
              <th scope="col">Client</th>
              <th scope="col">Promotion code Discount</th>
              <th scope="col">
                Discount
                <button
                  class="btn btn-sm btn-primary ml-3"
                  @click="editDiscounts()"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </th>
              <th>Additional Services</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in invoice.items" v-bind:key="item.id">
              <td>{{ item.provider_invoice_id || "-" }}</td>
              <td>{{ item.meta.title || "-" }}</td>
              <td
                @click="
                  item.gift_id ? $router.push('/gifts/' + item.gift_id) : ''
                "
              >
                {{ item.gift_id || "-" }}
              </td>
              <td
                @click="
                  item.order_id ? $router.push('/orders/' + item.order_id) : ''
                "
              >
                {{ item.order_id || "-" }}
              </td>
              <td>{{ item.gift ? item.gift?.status : item.order_id ? "dated" : "open" }}</td>
              <td v-html="getStatusBadge(item)"></td>
              <td>
                {{
                  `${item.purchase_price.amount} ${item.purchase_price.currency.code}`
                }}
              </td>
              <td>
                {{
                  `${item.selling_price.amount} ${item.selling_price.currency.code}`
                }}
              </td>
              <td>
                {{
                  `${item.credit_spent.amount} ${item.credit_spent.currency.code}`
                }}
              </td>
              <td>{{ item.payment_method }}</td>
              <td>{{ item.meta.supplier || item.meta.distributor || "-" }}</td>
              <td>{{ item.meta.client || "-" }}</td>
              <td>{{ item.meta.discounts ? item.meta.discounts.promotion_code : "-" }}</td>
              <td>
                {{ item.meta.discounts ? item.meta.discounts['discount'] : "0" }}
                <button
                  class="btn btn-sm btn-primary ml-3"
                  @click="editDiscount(item.id)"
                >
                  <i class="fa fa-edit"></i>
                </button>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-primary ml-3"
                  @click="showAdditionalServicesCostModal(item)"
                >
                  <i class="fa fa-coin"></i>
                </button>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-danger ml-3"
                  title="Cancel"
                  :disabled="item.status != 'purchased'"
                  @click="showCancellationModal(item)"
                >
                  <i class="fa fa-stop"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="float-right w-25 mt-3 mb-4 mr-2">
        <table class="w-100">
          <tr>
            <td>Number of Items</td>
            <td>{{ invoice.items.length }}</td>
          </tr>
          <tr>
            <td>Total Price</td>
            <td class="font-weight-bold">
              {{ `${invoice.meta["total_price"]} AED` }}
            </td>
          </tr>
          <tr>
            <td>Delivery Cost</td>
            <td>
              {{ `${invoice.meta.delivery_cost | 0} AED` }}
            </td>
          </tr>
          <tr>
            <td>Additional Cost</td>
            <td>
              <span>{{invoice.additional_cost}}</span> AED
            </td>
          </tr>
          <tr v-if="invoice.meta['vat']">
            <td>VAT(5%)</td>
            <td>{{ `${invoice.meta["vat"]} AED` }}</td>
          </tr>
          <tr class="text-danger">
            <td>Discount</td>
            <td>
              <span v-if="invoice.discount"> {{invoice.discount}} </span>
              <span v-else> 0.00 </span> AED
            </td>
          </tr>
          <tr class="text-danger">
            <td>Promotion code Discount</td>
            <td>
              <span v-if="invoice.promotion_code_discount"> {{invoice.promotion_code_discount}} </span>
              <span v-else> 0.00 </span> AED
            </td>
          </tr>
          <tr v-if="invoice.cart && invoice.cart.promotion" class="text-info">
            <td>Which promo code used?</td>
            <td>
              <span>
                <router-link
                    :to="{ name: 'promotions.show', params: { id: invoice.cart.promotion.id } }"
                    v-bind:key="invoice.id"
                    style="cursor: pointer"
                >
                  {{invoice.cart.promotion.code}}
                </router-link>
              </span>
            </td>
          </tr>
          <tr class="text-danger">
            <td>Paid Credit</td>
            <td >
              {{ `${invoice.items_sum_credit_spent} AED` }}
            </td>
          </tr>
          <tr>
            <td class="text-success">Payable</td>
            <td class="font-weight-bold text-success">
              {{ `${invoice.payable.amount} AED` }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <modal name="additional-services-costs-modal" height="auto">
      <div class="p-4">
        <button
          @click="$modal.hide('additional-services-costs-modal')"
          class="modal-close-button"
        >
          ❌
        </button>
        <div class="h3 mb-3">Additional Services Costs</div>
        <form action="">
          <key-value-input
            v-model="itemAdditionalServicesCosts.data"
            :editData="itemAdditionalServicesCosts.data"
          ></key-value-input>

          <button
            type="submit"
            class="btn btn-primary mt-3 w-100"
            @click.prevent="updateAdditionalServicesCosts"
          >
            Edit
          </button>
        </form>
      </div>
    </modal>

    <modal name="cancellation-modal" height="auto">
      <div class="p-4">
        <button
          @click="$modal.hide('cancellation-modal')"
          class="modal-close-button"
        >
          ❌
        </button>
        <div class="h3 mb-3">Cancel item</div>
        <form action="">
          <div>
            <label for="refunding-method">Refunding method:</label>
            <select
              v-model="cancellation.refunding_method"
              id="refunding-method"
              class="form-control form-control-sm"
            >
              <option value="manual">Manual</option>
              <option value="balance">Balance</option>
            </select>
          </div>

          <div class="mt-3">
            <label for="refunding-penalty">Refunding penalty:</label>
            <input
              v-model="cancellation.refunding_penalty"
              id="refunding-penalty"
              class="form-control form-control-sm"
              type="text"
            />
          </div>

          <button
            type="submit"
            class="btn btn-sm btn-danger mt-3 w-100"
            @click.prevent="cancelItem"
          >
            Cancel
          </button>
        </form>
      </div>
    </modal>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import { config } from "../../helpers";
import KeyValueInputVue from "../../components/KeyValueInput.vue";

export default {
  components: {
    "key-value-input": KeyValueInputVue,
  },

  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      invoice: null,

      itemAdditionalServicesCosts: {
        id: null,
        data: {},
      },

      cancellation: {
        invoiceItemId: null,
        refunding_method: null,
        refunding_penalty: null,
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/invoices";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.invoice = response.data.data;
        console.log(this.invoice);
        this.loaded = true;
      });
    },

    editDiscount(itemId) {
      let value = prompt("Enter a number for the discount...");

      axios
        .patch(`${this.url}/${this.id}/items/${itemId}`, {
          field: "meta->discounts->discount",
          value: value,
        })
        .then((response) => {
          this.loadData();
        });
    },

    async editDiscounts() {
      let value = prompt("Enter a number for the discount...");

      for (let item of this.invoice.items) {
        await axios.patch(`${this.url}/${this.id}/items/${item["id"]}`, {
          field: "meta->discounts->discount",
          value: value,
        });
      }

      this.loadData();
    },

    showAdditionalServicesCostModal(invoiceItem) {
      this.itemAdditionalServicesCosts.id = invoiceItem.id;
      this.itemAdditionalServicesCosts.data = invoiceItem.meta
        .additional_services_costs ?? { service: 0 };
      if (this.itemAdditionalServicesCosts.data.length === 0) {
        this.itemAdditionalServicesCosts.data = { service: 0 };
      }

      this.$modal.show("additional-services-costs-modal");
    },

    updateAdditionalServicesCosts() {
      axios
        .patch(
          `${this.url}/${this.id}/items/${this.itemAdditionalServicesCosts.id}`,
          {
            field: "meta->additional_services_costs",
            value: this.itemAdditionalServicesCosts.data ?? {},
          }
        )
        .then(() => {
          this.$modal.hide("additional-services-costs-modal");
          this.loadData();
        })
        .catch(() => {
          alert("Something went wrong!");
        });
    },

    showCancellationModal(invoiceItem) {
      this.cancellation.invoiceItemId = invoiceItem.id;
      this.$modal.show("cancellation-modal");
    },

    cancelItem() {
      axios
        .post(
          `${this.url}/${this.id}/items/${this.cancellation.invoiceItemId}/cancel`,
          this.cancellation
        )
        .then((res) => {
          alert("Item cancelled sucessfully!");
          this.loadData();
          this.$modal.hide("cancellation-modal");
        })
        .catch((error) => {
          const errorMessage = error.response.data?.errors
            ? Object.values(error.response.data?.errors).flat()[0]
            : error.response.data?.meta?.message;

          alert(errorMessage);
        });
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getOrderOrGiftId(item) {
      if (item.order_id) {
        return `<a @click="$router.push('/orders/${item.meta.order_id}')">${item.meta.order_id}</a>`;
      }

      if (item.gift_id) {
        return `<a @click="$router.push('/gifts/${item.meta.gift_id}')">${item.meta.gift_id}</a>`;
      }

      return "<a>-</a>";
    },

    getStatusBadge(item) {
      const badgeClass = {
        refunded: "danger",
        canceled: "danger",
        purchased: "success",
      }[item.status];

      return `<span class="badge badge-${badgeClass}">${item.status}</span>`;
    },
  },
};
</script>

<style scoped>
.cart-confirm {
  position: fixed;
  bottom: 0;
}
</style>