<template>
  <div class="w-100">
    <h1>Categories Management</h1>

    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-9">
          <div class="row">
            <div id="items" class="row mt-3">
              <div
                  class="item col-lg-3"
                  v-for="item in items"
                  v-bind:key="item.id"
                  :style="getPhotoUrl(item)"
                  @click="showUpdateModal(item)"
              >
                <div style="position: relative">
                  <i v-if="item.status === 1" class="alert alert-success">Active</i>
                  <i v-else-if="item.status === 0" class="alert alert-danger">Deactivated</i>
                  <i v-else class="alert alert-primary">Hidden</i>
                </div>
                <div class="item-overlay">
                  {{ item.title }}<br />
                  <i class="fa fa-hashtag"></i> {{ item.slug }}
                  <br>
                  <div>
                    mapped to :
                    <ul v-for="category in item.mapped_categories" :key="category.id">
                      <li>{{category.title}}</li>
                    </ul>
                  </div>


                </div>
              </div>

              <div class="item col-lg-3 add-item" @click="showCreateModal">
                <div class="text-center">
                  <i class="fa fa-plus"></i><br />
                  Create a category
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-3">
            <translator
                :items="items"
                :keys="translateKeys"
                model="Category"
            >
              <template #default="{ clicked }">
                <div class="add-item" @click="clicked">
                  <div class="text-center">
                    <i class="fa fa-language"></i><br />
                    Crate new or update Translate
                  </div>
                </div>
              </template>

            </translator>
        </div>
      </div>

    </div>


    <modal name="create-modal" height="auto">
      <div class="p-4">
        <div class="h3 mb-3">Create a category</div>
        <form action="">
          <div class="col-lg-12">
            <label>title</label>
            <input
                v-model="createForm.title"
                type="text"
                class="form-control"
                placeholder="Title"
                required
            />
          </div>

          <div class="col-lg-12">
            <label>Description</label>
            <input
                v-model="createForm.description"
                type="text"
                class="form-control"
                placeholder="Description..."
                required
            />
          </div>
          <div class="form-group col-lg-12">
            <label>mapped connect categories</label>
            <multiselect
                class="form-control"
                v-model="selectedCategories"
                :options="connectCategories"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="title"
                track-by="id"
                :preselect-first="false">
            </multiselect>
          </div>
          <div class="form-group col-lg-12">
            <label>status</label>
            <multiselect
                class="form-control"
                v-model="status"
                :options="statusList"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="title"
                track-by="id"
                :preselect-first="false">
            </multiselect>
          </div>
          <div class="form-group col-lg-12">
            <div class="mb-2 mt-3">
              <label for="formFile" class="form-label">Select a photo...</label>
              <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  @change="createFormUpload"
                  ref="createFormUpload"
              />
            </div>
            <div v-if="this.createForm.photo">
              <i class="fa fa-check"></i> Photo uploaded!
            </div>
          </div>
          <div class="col-lg-12">
            <label>Showing priority</label>
            <input
                v-model="createForm.priority"
                type="text"
                class="form-control"
                placeholder="priority..."
                required
            />
          </div>


          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="createCategory">
            Create
          </button>
        </form>
      </div>
    </modal>

    <modal name="update-modal" height="auto">
      <div class="p-4">
        <div class="h3 mb-3">Update a category</div>
        <form action="">
          <div class="col-lg-12">
            <label>title</label>
            <input
              v-model="updateForm.title"
              type="text"
              class="form-control"
              placeholder="Title"
              required
            />
          </div>
          <div class="col-lg-12">
            <label>Description</label>
            <input
              v-model="updateForm.description"
              type="text"
              class="form-control mt-3"
              placeholder="Description..."
              required
            />
          </div>
          <div class="form-group col-lg-12">
            <label>mapped connect categories</label>
            <multiselect
                class="form-control"
                v-model="selectedCategories"
                :options="connectCategories"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="title"
                track-by="id"
                :preselect-first="false">
            </multiselect>
          </div>
          <div class="form-group col-lg-12">
            <label>status</label>
            <multiselect
                class="form-control"
                v-model="status"
                :options="statusList"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="title"
                track-by="id"
                :preselect-first="false">
            </multiselect>
          </div>
          <div class="col-lg-12">
            <div class="mb-2 mt-3">
              <label for="formFile" class="form-label">Select a photo...</label>
              <input
                class="form-control"
                type="file"
                id="formFile"
                @change="updateFormUpload"
                ref="updateFormUpload"
              />
            </div>
            <div v-if="this.updateForm.photo">
              <i class="fa fa-check"></i> Photo uploaded!
            </div>
          </div>
          <div class="col-lg-12">
            <label>Showing priority</label>
            <input
                v-model="updateForm.priority"
                type="text"
                class="form-control"
                placeholder="priority..."
                required
            />
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in updateForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="updateCategory">
            Update
          </button>
          <button class="btn btn-danger mt-2 w-100" @click="deleteCategory">
            Delete
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { config,saveTranslate } from "../helpers";
import multiselect from 'vue-multiselect'
import Translator from "@/components/Translator.vue";

export default {
  name: "Categories",
  components: {multiselect, Translator},
  data() {
    return {
      connectCategories : [],
      selectedCategories : [],
      translateKeys: [
          {
            "key" : "title",
            "type": "text",
          },
        {
          "key" : "description",
          "type": "text",
        },
      ],
      url: "",
      items: [],
      status: '',
      statusList: [
        {
          "id" : 1,
          "title": "Active",
        },
        {
          "id" : 2,
          "title": "Hidden",
        },
        {
          "id" : 0,
          "title": "Deactivate",
        }
      ],
      createForm: {
        title: "",
        description: "",
        photo: "",
        priority: "",

      },
      updateForm: {
        id: null,
        title: "",
        description: "",
        priority: "",
        status: "",
        mapped_categories : []
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/categories";
    this.loadData();
    this.loadConnectCategories();
  },

  methods: {
    loadData() {
      axios.get(this.url).then((response) => {
        this.items = response.data.data;
      });
    },
    loadConnectCategories(){
      axios.get(this.url+"/connect-list").then((response) => {
        this.connectCategories = response.data.data;
      });
    },

    createCategory(event) {
      event.preventDefault();
      this.createForm.status = this.status.id;
      this.createForm.mapped_categories = this.selectedCategories.map(cat => cat.id);
      axios
        .post(this.url, this.createForm)
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          this.createForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    updateCategory(event)
    {
      event.preventDefault();
      this.updateForm.status = this.status.id;
      this.updateForm.mapped_categories = this.selectedCategories.map(cat => cat.id);

      axios
        .put(this.url + '/' + this.updateForm.id, this.updateForm)
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          this.updateForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    deleteCategory(event) {
      event.preventDefault();
      const confirm = prompt('Do you want to delete this category? Enter 8:');
      
      if (confirm == 8) {
        axios
        .delete(this.url + '/' + this.updateForm.id)
        .then((response) => {
          location.reload();
        });
      }
    },

    showCreateModal() {
      this.selectedCategories = [];
      this.$modal.show("create-modal");
    },

    showUpdateModal(item) {
      this.selectedCategories = [];
      this.updateForm = { id: null, title: "", slug: "", description: "", photo: "" };
      this.updateForm.id = item.id;
      this.updateForm.title = item.title;
      this.updateForm.description = item.description;
      this.status = this.statusList.filter(state => item.status === state.id);
      this.updateForm.priority = item.priority;
      this.updateForm.mapped_categories = item.mapped_categories;
      this.selectedCategories = item.mapped_categories;
      this.$modal.show("update-modal");
    },

    createFormUpload() {

      const formData = new FormData();
      formData.append("file", this.$refs.createFormUpload.files[0]);
      formData.append("type", this.$refs.createFormUpload.files[0].type.split('/')[0]);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.createForm.photo = res.data.data.file_name;
        })
        .catch((error) => {
          this.createForm.errors = error.response.data.errors.file;
        });
    },

    updateFormUpload() {
      const formData = new FormData();
      formData.append("file", this.$refs.updateFormUpload.files[0]);
      formData.append("type", this.$refs.updateFormUpload.files[0].type.split('/')[0]);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.updateForm.photo = res.data.data.file_name;
        })
        .catch((error) => {
          this.updateForm.errors = error.response.data.errors.file;
        });
    },

    getPhotoUrl(item) {
      if (!item.photo) {
        return "";
      }

      return `background:url("${item.photo.url}")`;
    },
  },
};
</script>
<style src="../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.item {
  background-size: cover !important;
  color: #fff;
  min-height: 100px;
  border-radius: 6px;
  margin: 5px !important;
  cursor: pointer;
}
.item-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 15px 20px;
  transition: 0.3s !important;
}
.item-overlay:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.add-item {
  color: #666;
  border: 2px dashed #999;
  padding-top: 17px;
  transition: 0.3s !important;
}
.add-item:hover {
  color: #f42f4b;
  border-color: #f42f4b;
}
.alert{
  position: absolute;
  top: 0;
  right: 0;
}
ul{
  list-style-type: none;
}
li{
  line-height: 12px;
}

</style>