<template>
  <div class="w-100">
    <h1 class="float-left">Meta data</h1>
    <button
      class="btn btn-success float-right"
      @click="$router.push(`/meta-data/create`)"
    >
      Create meta data
    </button>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Type</th>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" v-bind:key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.subject_type }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>
            <td>
              <div
                class="btn btn-sm btn-primary"
                @click="
                  $router.push(
                    `/meta-data/${item.subject_type}/${item.subject_id}/edit`
                  )
                "
              >
                Edit
              </div>
              <div class="btn btn-sm btn-danger ml-2" @click="deleteItem(item)">
                Delete
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <li
          class="page-item"
          v-bind:class="{ disabled: page == 1 }"
          @click="previousPage()"
        >
          <a class="page-link" href="#" tabindex="-1">Previous</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: page == lastPage }"
          @click="nextPage()"
        >
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  name: "MetaData",
  data() {
    return {
      url: `${config("BACKEND_ROOT")}/admin/meta-data`,

      items: [],

      page: 1,
      lastPage: 1,
    };
  },

  mounted() {
    this.loadItems();
  },

  methods: {
    loadItems() {
      let myData = { page: this.page };

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
      });
    },

    deleteItem(item) {
      if (!confirm("Do you want to delete this item?")) {
        return;
      }

      axios
        .delete(this.url + `/${item.subject_type}/${item.subject_id}`)
        .then((response) => {
          this.loadItems();
        });
    },

    nextPage() {
      if (this.page == this.lastPage) {
        return;
      }

      this.page++;
      this.loadItems();
    },

    previousPage() {
      if (this.page == 1) {
        return;
      }

      this.page--;
      this.loadItems();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>