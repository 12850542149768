<template>
  <div class="w-100">
    <h1>Booked List</h1>

    <div class="row">
      <div class="col-lg-12 mb-3 mt-2">
        <input
          placeholder="Search..."
          v-model="inputFilters.search"
          class="form-control"
          v-on:keyup.enter="filter"
        />
      </div>
      <div class="col-lg-4">
        <label for="purchase-datepicker">Purchase Date:</label>
        <date-picker
          v-model="inputFilters.book_date"
          id="purchase-datepicker"
          locale="en"
          format="YYYY-MM-DD"
          range
          clearable
          color="#f42f4b"
        />
      </div>
      <div class="col-lg-4">
        <label for="service-datepicker">Service Date:</label>
        <date-picker
          v-model="inputFilters.activity_date"
          id="service-datepicker"
          locale="en"
          format="YYYY-MM-DD"
          range
          clearable
          color="#f42f4b"
        />
      </div>
      <div class="col-lg-2">
        <label for="status">Order status:</label>
        <select v-model="inputFilters.status" id="status" class="form-control">
          <option value="Booked">Booked</option>
          <option value="InCart">InCart</option>
          <option value="Canceled">Canceled</option>
        </select>
      </div>
      <div class="col-lg-2">
        <label class="d-block" style="visibility: hidden">Filter</label>
        <button class="btn btn-primary" @click="filter">
          <i class="fas fa-search"></i>
        </button>
        <button
          class="btn btn-secondary ml-2"
          @click="exportToCSV"
          v-if="orders"
          title="Export to csv"
        >
          <i class="fas fa-cloud-download-alt"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">Cart ID</th>
            <th scope="col">Gift ID</th>
            <th scope="col">Order ID</th>
            <th scope="col">Customer</th>
            <th scope="col">Experience Name<br />City</th>
            <th scope="col">Supplier</th>
            <th scope="col">Purchase Date</th>
            <th scope="col">Service Date</th>
            <th scope="col">Status</th>
            <th scope="col">Tags</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            tag="tr"
            :to="{ name: 'order.show', params: { id: order.id } }"
            v-for="order in orders"
            v-bind:key="order.id"
            style="cursor: pointer"
          >
            <td
              @click.prevent="gotoCardPage(getCardId(order.tags))"
              style="color: #f42f4b"
            >
              {{ getCardId(order.tags) }}
            </td>
            <td
              @click.prevent="gotoGiftPage(getGiftId(order.tags))"
              style="color: #f42f4b"
            >
              {{ getGiftId(order.tags) }}
            </td>
            <td>{{ order.id }}</td>
            <td>{{ getOrderLeaderName(order.passengers) }}</td>
            <td>
              {{
                order.booked_details.product.title +
                " (" +
                order.booked_details.service_level.title +
                ")"
              }}<br />
              {{ order.booked_details.starting_point.city.name }}
            </td>
            <td>{{ order.supplier.name }}</td>
            <td>{{ formatDate(order.booked_at) }}</td>
            <td>{{ formatDate(order.starts_at) }}</td>
            <td v-html="getOrderStatus(order.status)"></td>
            <td v-html="getOrderTagLabels(order.tags)"></td>
            <td>
              <div
                class="btn btn-sm btn-secondary"
                @click.prevent="showSendTicketModal(order.id)"
              >
                Send Ticket
              </div>
            </td>
          </router-link>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>

    <modal name="send-ticket-modal" height="auto">
      <div class="p-4">
        <button
          @click="$modal.hide('send-ticket-modal')"
          class="modal-close-button"
        >
          ❌
        </button>
        <div class="h3 mb-3">Send ticket</div>
        <p>
          Are you going to send this ticket to the leader of the order #{{
            this.ticket.orderId
          }}?
        </p>
        <form action="">
          <input
            v-model="ticket.ticketUrl"
            type="text"
            class="form-control"
            placeholder="Ticket URL"
            required
          />

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in ticket.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="sendTicket">
            Send
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  name: "Orders",
  data() {
    return {
      url: "",
      orders: [],
      inputFilters: {
        search: null,
        status: null,
        activity_date: [],
        book_date: [],
      },
      isFiltered: false,
      filters: {},

      totalItems: 0,
      page: 1,
      lastPage: 1,
      perPage: 1,

      ticket: {
        orderId: null,
        ticketUrl: null,
        errors: [],
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/orders";
    this.loadOrders();
  },

  methods: {
    loadOrders() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = { ...myData, filters };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.orders = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.search) {
        filters.search = this.inputFilters.search;
      }

      if (this.inputFilters.status) {
        filters.status = this.inputFilters.status;
      }

      if (this.inputFilters.activity_date[0]) {
        filters.activity_date = {};
        filters.activity_date.start = this.inputFilters.activity_date[0];
        filters.activity_date.end = this.inputFilters.activity_date[1];
      }

      if (this.inputFilters.book_date[0]) {
        filters.book_date = {};
        filters.book_date.start = this.inputFilters.book_date[0];
        filters.book_date.end = this.inputFilters.book_date[1];
      }

      this.filters = filters;

      this.loadOrders();
    },

    exportToCSV() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = { ...myData, filters };
      }

      axios.get(this.url + "/export", { params: myData }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },

    getCardId(orderTags) {
      for (let orderTag of orderTags) {
        if (orderTag.key == "dd_cart_id") {
          return orderTag.value;
        }
      }

      return "?";
    },

    getGiftId(orderTags) {
      for (let orderTag of orderTags) {
        if (orderTag.key == "dd_gift_id") {
          return orderTag.value;
        }
      }

      return "?";
    },

    gotoCardPage(cardId) {
      this.$router.push(`/carts/${cardId}`);
    },

    gotoGiftPage(giftId) {
      this.$router.push(`/gifts/${giftId}`);
    },

    getOrderLeaderName(passengers) {
      for (let passenger of passengers) {
        if (passenger.is_leader) {
          return `${passenger.first_name} ${passenger.last_name}`;
        }
      }
    },

    formatDate(date) {
      date = new Date(date);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getOrderStatus(status) {
      if (status == "Booked") {
        return '<span class="badge badge-success">Booked</span>';
      } else if (status == "InCart") {
        return '<span class="badge badge-secondary">InCart</span>';
      } else {
        return '<span class="badge badge-danger">Cancelled</span>';
      }
    },

    getOrderTagLabels(tags) {
      let output = "";

      if (tags.some((tag) => tag.key.startsWith("dd_gift_"))) {
        output += '<span class="badge badge-primary">Gift</span>';
      }

      return output || "-";
    },

    showSendTicketModal(orderId) {
      this.ticket.orderId = orderId;
      this.ticket.ticketUrl = "";
      this.ticket.errors = [];
      this.$modal.show("send-ticket-modal");
    },

    sendTicket(event) {
      event.preventDefault();
      this.ticket.errors = [];

      axios
        .post(
          config("BACKEND_ROOT") +
            "/admin/orders/" +
            this.ticket.orderId +
            "/send-ticket",
          { ticket_url: this.ticket.ticketUrl }
        )
        .then((res) => {
          alert("Ticket has been sent!");
          this.$modal.hide("send-ticket-modal");
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.ticket.errors = [error.response.data.meta.message];
          } else {
            this.ticket.errors = error.response.data.errors.ticket_url;
          }
        });
    },
  },

  watch: {
    page: function (val) {
      this.loadOrders();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>