<template>
  <div class="p-4">
    <button @click="$modal.hide('comments-modal')" class="modal-close-button">
      ❌
    </button>

    <div class="h3 mb-3">Edit comments</div>

    <div class="form">
      <div class="row">
        <div class="form-group col-lg-12">
          <input class="form-control" v-model="form.text">
        </div>
      </div>
    </div>

    <ul class="mb-2" v-if="errors" style="padding-left:20px;">
      <li v-for="error in errors" v-bind:key="error" class="text-danger">
        {{ error }}
      </li>
    </ul>

    <button
      class="btn btn-primary mt-2 w-100"
      @click.prevent="submit()"
    >
      Update
    </button>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["giftId", "giftComments"],

  data() {
    return {
      form: {
        text: '',
      },

      errors: [],
    };
  },

  mounted() {
    this.form.text = this.giftComments;
  },

  methods: {
    submit() {
      axios
        .patch(`${config("BACKEND_ROOT")}/admin/gifts/${this.giftId}/comments`, this.form)
        .then((response) => {
          this.$emit("done");
          alert('Comments updated!');
          this.$modal.hide("comments-modal");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },
  },
};
</script>