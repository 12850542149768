<template>
  <div class="w-100">
    <h1>Reviews List</h1>

    <div class="row">
      <div class="col-lg-5 mb-3 mt-2">
        <input
          placeholder="Search..."
          v-model="inputFilters.q"
          class="form-control"
        />
      </div>
      <div class="col-lg-2">
        <button class="btn btn-primary" @click="filter" style="margin-top: 8px">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID
              <table-sort-arrow column="id" :current="sort.by" @change="sortChanged"></table-sort-arrow>
            </th>
            <th scope="col">Category</th>
            <th scope="col">Experience</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Customer Email</th>
            <th scope="col">Rating
              <table-sort-arrow column="rating" :current="sort.by" @change="sortChanged"></table-sort-arrow>
            </th>
            <th scope="col">Title</th>
            <th scope="col">Text</th>
            <th scope="col">Trip Type</th>
            <th scope="col">Status
              <table-sort-arrow column="status" :current="sort.by" @change="sortChanged"></table-sort-arrow>
            </th>
            <th scope="col">Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="review in items"
            v-bind:key="review.id"
            :class="review.status == 1 ? 'new-response' : ''"
          >
            <td>{{ review.id }}</td>
            <td>{{ review.category_title }}</td>
            <td>{{ review.product_title }}<br>{{ review.service_level_title }}</td>
            <td>{{ `${review.user.first_name} ${review.user.last_name}` }}</td>
            <td>{{ review.user.email }}</td>
            <td>{{ "⭐️".repeat(review.rating) }}</td>
            <td>{{ review.title }}</td>
            <td class="text-column">{{ review.text }}</td>
            <td>{{ review.trip_type }}</td>
            <td v-html="getStatus(review.status)"></td>
            <td>{{ formatDate(review.created_at) }}</td>
            <td>
              <button
                :class="
                  review.status == 1
                    ? 'btn btn-sm btn-success'
                    : 'btn btn-sm btn-warning'
                "
                :title="review.status == 1 ? 'Publish' : 'Unpublish'"
                @click="togglePublish(review)"
              >
                <i
                  :class="
                    review.status == 1 ? 'fa fa-check' : 'fa fa-eye-slash'
                  "
                ></i>
              </button>
              <button
                class="btn btn-sm btn-primary ml-2"
                title="Edit"
                @click="showEditModal(review)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                class="btn btn-sm btn-danger ml-2"
                title="Delete"
                @click="destroy(review.id)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <li
          class="page-item"
          v-bind:class="{ disabled: page == 1 }"
          @click="previousPage()"
        >
          <a class="page-link" href="#" tabindex="-1">Previous</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: page == lastPage }"
          @click="nextPage()"
        >
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>

    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-3">Edit a review</div>
        <form action="">
          <div class="row mt-2">
            <div class="col-lg-8">
              <label for="edit-form__title">Title:</label>
              <input
                v-model="updateForm.title"
                type="text"
                class="form-control"
                id="edit-form__title"
                placeholder="Description, some alternative text"
                required
              />
            </div>

            <div class="col-4">
              <label for="edit-form__status">Status:</label>
              <select
                v-model="updateForm.status"
                class="form-control"
                id="edit-form__status"
              >
                <option value="1">Pending</option>
                <option value="2">Published</option>
              </select>
            </div>
          </div>

          <textarea
            v-model="updateForm.text"
            class="form-control mt-3"
            rows="4"
            placeholder="Text"
            required
          ></textarea>

          <div class="row mt-3">
            <div class="col-6">
              <label for="edit-form__rating">Rating (1-5):</label>
              <select
                v-model="updateForm.rating"
                id="edit-form__rating"
                class="form-control"
              >
                <option value="1">⭐️</option>
                <option value="2">⭐️⭐️</option>
                <option value="3">⭐️⭐️⭐️</option>
                <option value="4">⭐️⭐️⭐️⭐️</option>
                <option value="5">⭐️⭐️⭐️⭐️⭐️</option>
              </select>
            </div>

            <div class="col-6">
              <label for="edit-form__triptype">Trip Type:</label>
              <select
                v-model="updateForm.trip_type"
                id="edit-form__triptype"
                class="form-control"
              >
                <option value="Solo">Solo</option>
                <option value="Business">Business</option>
                <option value="Friends">Friends</option>
                <option value="Family (teens)">Family (teens)</option>
                <option value="Family (young children)">
                  Family (young children)
                </option>
                <option value="Couples">Couples</option>
              </select>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary mt-3 w-100"
            @click.prevent="update()"
          >
            Update
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import TableSortArrow from '../components/TableSortArrow.vue';
import { config } from "../helpers";

export default {
  name: "Reviews",
  components: { 'table-sort-arrow': TableSortArrow },
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: null,
      },
      isFiltered: false,
      filters: {},
      sort: {},
      page: 1,
      lastPage: 1,

      updateForm: {},
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/reviews";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        myData = { ...myData, ...this.filters };
      }

      if (this.sort) {
        myData = { ...myData, sort_by: this.sort.by, sort_order: this.sort.order };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      this.filters = filters;

      this.loadData();
    },

    sortChanged: _.debounce(function (sort) {
        this.page = 1;
        this.sort = sort;
        this.loadData();
    }, 500),

    togglePublish(review) {
      if (!confirm("Do you want to publish this review?")) {
        return;
      }

      let newStatus = review.status == 1 ? 2 : 1;

      axios
        .put(`${this.url}/${review.id}`, { ...review, status: newStatus })
        .then((response) => {
          this.loadData();
        });
    },

    showEditModal(review) {
      this.updateForm = {
        id: review.id,
        title: review.title,
        text: review.text,
        rating: review.rating,
        trip_type: review.trip_type,
        status: review.status,
      };

      this.$modal.show("update-modal");
    },

    update() {
      axios
        .put(`${this.url}/${this.updateForm.id}`, {
          title: this.updateForm.title,
          text: this.updateForm.text,
          rating: this.updateForm.rating,
          trip_type: this.updateForm.trip_type,
          status: this.updateForm.status,
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("update-modal");
        })
        .catch((error) => {
          alert(Object.values(error.response.data.errors).flat()[0]);
        });
    },

    destroy(reviewId) {
      if (!confirm("Are you sure about deleting this review?")) {
        return;
      }

      axios.delete(`${this.url}/${reviewId}`).then((response) => {
        this.loadData();
      });
    },

    nextPage() {
      if (this.page == this.lastPage) {
        return;
      }

      this.page++;
      this.loadData();
    },

    previousPage() {
      if (this.page == 1) {
        return;
      }

      this.page--;
      this.loadData();
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getStatus(status) {
      let classes = {
        1: "badge-warning",
        2: "badge-success",
      };

      return `<div class="badge ${classes[status]}">${
        status == 1 ? "Pending" : "Published"
      }</div>`;
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}

.new-response {
  background: #fff7d8;
}

.text-column {
  display: inline-block;
  width: 300px;
  white-space: pre-line !important;
}
</style>