<template>
  <div class="w-100">
    <h1 class="mb-4">Create meta data</h1>

    <div class="form">
      <label for="">Type:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <select
            v-model="subjectType"
            @change="loadSubjects()"
            class="form-control"
          >
            <option
              v-for="type in subjectTypes"
              :value="type"
              v-bind:key="type"
            >
              {{ type.title }}
            </option>
          </select>
        </div>
      </div>

      <label for="">Select Item:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <select v-model="subjectId" class="form-control">
            <option
              v-for="subject in subjects"
              :value="subject.id"
              v-bind:key="subject.id"
            >
              {{ subject.title }}
            </option>
          </select>
        </div>
      </div>

      <label for="">Meta title:</label>
      <div class="form-row">

        <div class="form-group col-lg-12">
          <input
            v-model="form.title"
            class="form-control"
            placeholder="Title"
          />
        </div>
      </div>

      <label for="">Meta description:</label>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <input
            v-model="form.description"
            class="form-control"
            placeholder="Description"
          />
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <button type="submit" class="btn btn-success pl-4 pr-4" @click="submit()">
        Create
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { config } from "../../../helpers";
import metadataOptions from "@/static/metadata.json"

export default {
  name: "MetaDataCreate",
  data() {
    return {
      subjectTypes: metadataOptions,

      subjects: [],

      subjectType: null,
      subjectId: null,

      form: {
        title: null,
        description: null,
      },
      errors: [],
    };
  },

  methods: {
    loadSubjects() {
      this.subjects = [];
      if(this.subjectType.value != 0){
        if(this.subjectType.extra_data){
          this.subjects.push(this.subjectType.extra_data);
        }
        axios
            .get(`${config("BACKEND_ROOT")}/${this.subjectType.title}`)
            .then((response) => {
              this.subjects =[...this.subjects,...response.data.data];
            })
            .catch((err) => {
              axios
                  .get(`${config("BACKEND_ROOT")}/admin/${this.subjectType.title}`)
                  .then((response) => {
                    this.subjects =[...this.subjects,...response.data.data];
                  });
            });
      }
      else{
        this.subjects = [];
        this.subjects.push({'id' : this.subjectType.value, 'title': this.subjectType.title});
      }
    },

    submit() {
      axios
        .post(
          `${config("BACKEND_ROOT")}/admin/meta-data/${this.subjectType.title}/${
            this.subjectId
          }`,
          this.form
        )
        .then((response) => {
          this.$router.push("/meta-data");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },
  },
};
</script>
