<template>
  <div class="w-100">
    <h1>Site Redirects List</h1>

    <div class="row">

      <div class="col-lg-12">
        <button class="btn btn-success float-right" @click="showCreateModal">Create a New Redirect</button>
      </div>
    </div>
    <br>

    <div class="row">
      <div class="col-md-12">
        <div class="callout callout-info">
          <h5><i class="fas fa-info"></i> Note:</h5>
          Status means:<br>
          <span class="badge badge-warning">Need Handle</span> : This status indicates that a new url has been added to the list
          , but its status is not clear where to redirect <br>
          <span class="badge badge-success">Active</span> : This status indicates that the address is fine and the previous URL has been
          redirected to a new URL.
          Also, this redirect is active for both users and Google <br>
          <span class="badge badge-danger">Will Delete</span>: This status indicates that the redirect is old.
          Usually, after 6 months, Google completely ignores the previous internet address and there
          is no need to redirect. So the system suggests to remove it from the list
        </div>
        <div class="callout callout-info">
          <h5><i class="fas fa-info"></i> Note:</h5>
          When a product is inactive or deleted, it will be automatically added to this list with an
          <span class="badge badge-warning">«Need Handle»</span>
          status, and you need to manage it to make it  <span class="badge badge-success">«Active»</span>.
        </div>
      </div>
    </div>

    <div class="callout callout-info">
      <div class="col-lg-8">
        <input
            placeholder="Search by from url, to url..."
            v-model="search"
            class="form-control d-inline w-75 mr-2"
        />
        <button
            class="btn btn-primary d-inline"
            @click="loadData"
            style="margin-top: -4px"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body p-0">
            <ul class="products-list product-list-in-card pl-2 pr-2">
              <li v-for="item in items" class="item">
                <div class="product-img">
                  <img src="../../../assets/google-robot.png" alt="Product Image" class="img-size-50">
                </div>
                <div class="product-info">
                  <span class="product-description">
                    From : {{item.from}}
                  </span>
                    <div v-if="item.to">
                      To: <a :href="`${item.domain}${item.to}`" target="_blank" class="product-title">{{item.to}}</a>
                    </div>
                    <span class="product-description">
                    HTTP Code : {{item.status_code}}
                  </span>
                  <span class="product-description">
                    Date : {{moment(item.creation_date).format('YYYY-MM-DD HH:mm:ss')}}
                  </span>
                    <span v-if="item.status === 0" class="badge badge-warning">Need Handle</span>
                    <span v-else-if="item.status === 1" class="badge badge-success">Active</span>
                    <span v-else class="badge badge-danger">Will Delete</span>

                    <div class="actions">
                      <span @click="showUpdateModal(item)" style="cursor: pointer" class="badge badge-success float-right">Edit<i class="fa fa-edit"></i></span>
                      <br><br>
                      <span @click="removeRedirect(item)" style="cursor: pointer" class="badge badge-danger float-right">Remove <i class="fa fa-close"></i></span>
                    </div>

                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <modal name="create-modal" height="auto">
      <div class="p-4">
        <div class="h3 mb-3">Create a Redirect</div>
        <form>
          <div class="col-lg-12">
            <label>From (only path without domain)</label>
            <input
                v-model="create.from"
                type="text"
                class="form-control"
                placeholder=""
                required
            />
          </div>
          <div class="form-group col-lg-12">
            <label>HTTP code</label>
            <multiselect
                class="form-control"
                v-model="status_code"
                :options="status_codes"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                :preselect-first="false">
            </multiselect>
          </div>

          <div v-if="status_code != 410" class="col-lg-12">
            <label>To (only path without domain)</label>
            <input
                v-model="create.to"
                type="text"
                class="form-control"
                placeholder=""
                required
            />
          </div>

          <button @click="createRedirect()" class="btn btn-primary mt-3 w-100">
            Create
          </button>
        </form>
      </div>
    </modal>
    <modal name="update-modal" height="auto">
      <div class="p-4">
        <div class="h3 mb-3">Update Redirect</div>
        <form>
          <div class="col-lg-12">
            <label>From (only path without domain)</label>
            <input
                v-model="update.from"
                type="text"
                class="form-control"
                placeholder=""
                required
            />
          </div>
          <div class="form-group col-lg-12">
            <label>HTTP code</label>
            <multiselect
                class="form-control"
                v-model="status_code"
                :options="status_codes"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                :preselect-first="false">
            </multiselect>
          </div>

          <div v-if="status_code != 410" class="col-lg-12">
            <label>To (only path without domain)</label>
            <input
                v-model="update.to"
                type="text"
                class="form-control"
                placeholder=""
                required
            />
          </div>

          <button @click="updateRedirect()" class="btn btn-primary mt-3 w-100">
            Update
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { config } from "../../../helpers";
import multiselect from "vue-multiselect";
export default {
  components: {multiselect},
  computed: {
    moment() {
      return moment
    }
  },
  data() {
    return {
      status_codes : ["301", "302", "410"],
      status_code: '',
      items: [],
      search: '',
      create: {
        from: '',
        to: '',
        status_code: ''
      },
      update: {
        from: '',
        to: '',
        status_code: '',
        id: ''
      }
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/seo/redirects";
    this.loadData();
  },

  methods: {
    loadData() {

      const query = this.search !== '' ? '?search='+this.search : '';
      axios.get(`${this.url}${query}`).then((response) => {
        this.items = response.data.data;
      });
    },

    createRedirect(){
      event.preventDefault();
      this.create.status_code = this.status_code;
      axios.post(this.url, this.create)
          .then((response) => {
            this.$notify({
              group: 'notify',
              type: 'success',
              title: 'Hooray!',
              text: "Data added successfully"
            });
            setTimeout(function(){
              location.reload()
            }, 2500);
          })
          .catch((error) => {
            this.$notify({
              group: 'notify',
              type: 'error',
              title: 'Error',
              text: error.response.data.errors !== null ? Object.values(
                  error.response.data.errors
              ).flat() : error.response.data.meta.message
            });
          });
    },
    updateRedirect(){
      event.preventDefault();
      this.update.status_code = this.status_code;
      axios.patch(this.url+'/'+this.update.id, this.update)
          .then((response) => {
            this.$notify({
              group: 'notify',
              type: 'success',
              title: 'Hooray!',
              text: "Data updated successfully"
            });
            setTimeout(function(){
              location.reload()
            }, 2500);
          })
          .catch((error) => {
            this.$notify({
              group: 'notify',
              type: 'error',
              title: 'Error',
              text: error.response.data.errors !== null ? Object.values(
                  error.response.data.errors
              ).flat() : error.response.data.meta.message
            });
          });
    },

    removeRedirect(item){
      if(confirm("Do you really want to delete?")){

        axios.delete(this.url+'/'+item.id)
          .then(resp => {
            this.$notify({
              group: 'notify',
              type: 'success',
              title: 'Hooray!',
              text: "Data Deleted successfully"
            });
            setTimeout(function(){
              location.reload()
            }, 2500);
          })
          .catch(error => {
            this.$notify({
              group: 'notify',
              type: 'error',
              title: 'Error',
              text: error.response.data.errors !== null ? Object.values(
                  error.response.data.errors
              ).flat() : error.response.data.meta.message
            });
          })
      }
    },

    showCreateModal() {
      this.$modal.show("create-modal");
    },
    showUpdateModal(item) {
      this.status_code = '';
      this.update.id = item.id;
      this.update.from = item.from;
      this.update.to = item.to;
      this.status_code = item.status_code;
      this.$modal.show("update-modal");
    },
  },
};
</script>

<style scoped>
.card
{
  background-color: #343a40;
  color: #343a40;
}
.card
{
  box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
  margin-bottom: 1rem;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
.card-body
{
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.products-list
{
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 0px;
  padding-left: 0px;
}
.p-0
{
  padding: 0 !important;
}
.pl-2, .px-2
{
  padding-left: .5rem !important;
}
.pr-2, .px-2
{
  padding-right: .5rem !important;
}
.products-list
{
  list-style: none;
}
.products-list > .item
{
  background-color: #343a40;
  color: #fff;
}
.product-list-in-card > .item
{
  border-radius: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);
  border-bottom-color: rgba(0, 0, 0, 0.125);
}
.products-list > .item
{
  border-radius: .25rem;
  padding: 10px 0;
}
.products-list .product-img
{
  float: left;
}
.products-list .product-info
{
  margin-left: 60px;
  position: relative;
}
.products-list > .item::after
{
  display: block;
  clear: both;
  content: "";
}
.products-list .product-title
{
  font-weight: 600;
}
a
{
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.product-description
{
  color: #ced4da;
}
.products-list .product-description
{
  color: #fff;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
*, ::after, ::before
{
  box-sizing: border-box;
}
.product-list-in-card > .item
{
  border-radius: 0;
  border-bottom: 1px solid rgba(0,0,0,.125);
  border-bottom-color: rgba(0, 0, 0, 0.125);
}
img
{
  vertical-align: middle;
  border-style: none;
}
.products-list .product-img img
{
  height: 50px;
  width: 50px;
}
li {
  margin-bottom: 5px;
}
.callout.callout-info
{
  border-left-color: #117a8b;
}
.callout
{
  border-radius: .25rem;
  box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
  background-color: #fff;
  border-left: 5px solid #e9ecef;
  border-left-color: rgb(233, 236, 239);
  margin-bottom: 1rem;
  padding: 1rem;
}
.actions{
  position: absolute;
  top: 1px;
  right: 10px;
}
</style>