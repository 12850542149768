<template>
  <div class="w-100">
    <h1>Invoice List</h1>

    <div class="row mt-4">
      <div class="col-lg-3 mb-3" style="margin-top: 1px;">
        <label>By code, or customer's name or id:</label>
        <input
          placeholder="Search..."
          v-model="inputFilters.q"
          class="form-control"
          v-on:keyup.enter="filter"
        />
      </div>
      <div class="col-lg-3">
        <label for="date-datepicker">Filter by date:</label>
        <date-picker
          v-model="inputFilters.date"
          id="date-datepicker"
          locale="en"
          format="YYYY-MM-DD"
          range
          clearable
          color="#f42f4b"
        />
      </div>
      <div class="col-lg-2">
        <label class="d-block" style="visibility: hidden">Filter</label>
        <button class="btn btn-primary" @click="filter">
          <i class="fas fa-search"></i>
        </button>

        <button
          class="btn btn-secondary ml-2"
          @click="exportToCSV"
          v-if="items"
          title="Export to csv"
        >
          <i class="fas fa-cloud-download-alt"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-2">
        <thead class="thead-light">
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Date</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Provider</th>
            <th scope="col">Payable</th>
            <th scope="col">Buyer</th>
            <th scope="col">Status</th>
            <th scope="col">Payment Method</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            tag="tr"
            :to="{ name: 'invoice.show', params: { id: invoice.id } }"
            v-for="invoice in items"
            v-bind:key="invoice.id"
            style="cursor: pointer"
          >
            <td>{{ "DD00" + invoice.code }}</td>
            <td>{{ formatDate(invoice.created_at) }}</td>
            <td>{{ invoice.customer_name }}</td>
            <td>{{ invoice.provider }}</td>
            <td>
              {{ invoice.payable.amount + " " + invoice.payable.currency.code }}
            </td>
            <td>{{ invoice.buyer_type }}</td>
            <td>{{ getStatuses(invoice) }}</td>
            <td>{{ getPaymentMethods(invoice) }}</td>
          </router-link>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>
  </div>
</template>

<script>
import { config } from "../../helpers";

export default {
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: "",
        date: [],
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      totalItems: 1,
      perPage: 1,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/invoices";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        myData = { ...myData, date: this.filters.date, q: this.filters.q };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.date[0]) {
        filters.date = {};
        filters.date.start = this.inputFilters.date[0];
        filters.date.end = this.inputFilters.date[1];
      }

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      this.filters = filters;

      this.loadData();
    },

    exportToCSV() {
      let myData = { };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = {...filters };
      }

      axios.get(this.url + "/export", { params: myData }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoices.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getStatuses(invoice) {
      let statuses = invoice.items.map((item) => item["status"]);

      return [...new Set(statuses)].join(", ");
    },

    getPaymentMethods(invoice) {
      let methods = invoice.items.map((item) => item["payment_method"]);

      return [...new Set(methods)].join(", ");
    },
  },

  watch: {
    page: function (val) {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>