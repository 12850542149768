import Vue from "vue";

require('dotenv').config();

export function config(key) {
    return process.env[`VUE_APP_${key}`]
}

export function short(string, limit) {
    if (! string) {
        return string;
    }

    if (string.length <= limit) {
        return string;
    }

    return string.substr(0, limit) + '...';
}