<template>
  <div class="p-4">
    <button @click="$modal.hide('renew-modal')" class="modal-close-button">
      ❌
    </button>

    <div class="h3 mb-3">Extend gift expiration</div>

    <div class="form">
      <div class="row">
        <div class="form-group col-lg-12">
          <date-picker
            v-model="form.new_expiration_date"
            id="date-datepicker"
            locale="en"
            format="YYYY-MM-DD"
            clearable
            color="#f42f4b"
            placeholder="New expiration date"
          >
          </date-picker>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-12">
          <div class="input-group">
            <input
              v-model="form.cost"
              type="text"
              class="form-control"
              aria-describedby="basic-addon2"
              placeholder="Renew cost"
            />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">AED</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <search-box
            @typing="searchUser"
            @selected="selectUser"
            :results="searched_users.map(user => user.email)"
            placeholder="Search by email, phone or name"
            class="w-100"
          ></search-box>
        </div>
      </div>

      <div class="row mt-3" v-if="form.email != ''">
        <div class="col-lg-6 pt-2">
          <div class="form-check">
            <input
              v-model="balance_selected"
              class="form-check-input"
              type="checkbox"
              value="balance"
              id="confirm-modal__balance-option"
              :disabled="0 == 1"
            />
            <label class="form-check-label" for="confirm-modal__balance-option">
              Balance (Current: {{ current_user_balance }})
            </label>
          </div>
        </div>

        <div class="col-lg-6">
          <select v-model="form.payment_method" class="form-control">
              <option value="">Select another method</option>
              <option :value="method" v-for="method of available_methods" v-bind:key="method">
                {{ method.charAt(0).toUpperCase() + method.slice(1) }}
              </option>
          </select>
        </div>
      </div>

      <div class="row mt-2 mb-2">
        
      </div>
    </div>

    <ul class="mb-2" v-if="errors" style="padding-left:20px;">
      <li v-for="error in errors" v-bind:key="error" class="text-danger">
        {{ error }}
      </li>
    </ul>

    <button
      class="btn btn-success mt-2 w-100"
      @click.prevent="submit()"
      :disabled="form == 0"
    >
      Confirm
    </button>
  </div>
</template>

<script>
import { config } from "../../../helpers";
import SearchBox from '../../SearchBox.vue';

export default {
  components: {
    "search-box": SearchBox,
  },

  props: ["giftId"],

  data() {
    return {
      available_methods: [
        "credit card",
        "cash",
        "paypal",
        "cheque",
        "bank transfer",
      ],

      searched_users: [],
      current_user_balance: 0,
      
      form: {
        email: "",
        new_expiration_date: "",
        cost: null,
        payment_method: "",
      },
      errors: [],

      balance_selected: false,
    };
  },

  methods: {
    submit() {
      let paymentMethod = [];

      if (this.form.payment_method) {
        paymentMethod.push(this.form.payment_method);
      }

      if (this.balance_selected) {
        paymentMethod.push('balance');
      }

      axios
        .post(`${config("BACKEND_ROOT")}/admin/gifts/${this.giftId}/renew`, {
          email: this.form.email,
          new_expiration_date: this.form.new_expiration_date,
          cost: this.form.cost,
          payment_method: paymentMethod,
        })
        .then((response) => {
          this.$emit("done");
          alert('Expiration date changed successfully!');
          this.$modal.hide("renew-modal");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },

    searchUser: _.debounce(function (q) {
      axios
        .get(`${config("BACKEND_ROOT")}/admin/users?q=${q}`)
        .then((response) => {
          let users = [];
          for (let user of response.data.data) {
            users.push(user);
          }

          this.searched_users = users;
        });
    }, 500),

    selectUser(email) {
      const user = this.searched_users.find(el => el.email == email);
      this.form.email = user.email;
      this.current_user_balance = user.balance;
    },
  },
};
</script>