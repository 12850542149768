<template>
  <div class="w-100" v-if="loaded">
    <h1 class="mb-4">Edit collection</h1>

    <div class="form">
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model="form.title"
            class="form-control"
            placeholder="Title of the collection"
          />
        </div>
        <div class="form-group col-lg-6">
          <input
              v-model="form.slug"
              class="form-control"
              placeholder="UNIQUE Slug: using for collection url"
          />
        </div>

        <div class="form-group col-lg-6">
          <select
            class="form-control"
            title="Collection color"
            v-model="form.color"
          >
            <option
              :value="color"
              v-for="color in colors"
              v-bind:key="color"
              v-text="color.charAt(0).toUpperCase() + color.slice(1)"
            ></option>
          </select>
        </div>
      </div>

      <hr />

      <h5>Choose products</h5>
      <div class="row">
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-7">
              <label for="categoryLabel">Search : </label>
              <input
                  v-model="search"
                  class="form-control"
                  placeholder="search product title"
                  @keyup="loadExperiences"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <ul class="list-group experiences__list-group__items">
            <li
              class="list-group-item list-group-item-action"
              v-for="experience of experiences"
              v-bind:key="experience.id"
            >
              <strong class="mr-3">{{ experience.title }}</strong>

              <div
                class="d-inline mr-2 badge badge-primary cursor-pointer"
                v-for="serviceLevel of experience.service_levels"
                v-bind:key="serviceLevel.id"
                @click="
                  addProduct(
                    experience.id,
                    experience.title,
                    serviceLevel.id,
                    serviceLevel.title,
                    experience.thumbnail.url
                  )
                "
              >
                {{ serviceLevel.title }}
              </div>
            </li>
          </ul>

          <div class="selected-products">
            <div
              class="badge badge-success cursor-pointer mr-2"
              v-for="product of form.products"
              v-bind:key="product.service_level_id"
              @click="removeProduct(product.service_level_id)"
            >
              {{ `${product.product_title}: ${product.service_level_title} ` }}
              x
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="form-row">
        <div class="form-group col-lg-12">
          <textarea
            v-model="form.short_description"
            placeholder="Short description"
            class="form-control"
            cols="30"
            rows="2"
          ></textarea>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-lg-12">
          <textarea
            v-model="form.description"
            placeholder="Description"
            class="form-control"
            cols="30"
            rows="4"
          ></textarea>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-lg-12">
          <textarea
            v-model="form.need_to_know"
            placeholder="Need to know"
            class="form-control"
            cols="30"
            rows="2"
          ></textarea>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="">Highlights</label>
          <array-box
            :array="form.highlights"
            @update="(arr) => (form.highlights = arr)"
          ></array-box>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            placeholder="Youtube URL"
            v-model="form.video_url"
            class="form-control"
          />
        </div>
      </div>

      <hr />

      <div class="form-row">
        <div class="form-group col-lg-4">
          <label for="priceInput">Price</label>
          <div class="input-group mb-3">
            <input
              v-model="form.price"
              type="text"
              id="priceInput"
              class="form-control"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">AED</span>
            </div>
          </div>
        </div>

        <div class="col-lg-2"></div>

        <div class="form-group col-lg-5 mt-3 pt-3">
          <label for="defaultPeopleCountInput" class="ml-3"
            >Default people count:</label
          >
          <input
            type="number"
            min="1"
            v-model="form.default_people_count"
            class="form-control d-inline ml-2 col-lg-2"
            id="defaultPeopleCountInput"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" v-if="loaded">
           <MultipleFilesUpload :photos="form.images" @done="urls => form.photos = urls" label="Choose some photos..."></MultipleFilesUpload>
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <button
        type="submit"
        class="btn btn-primary pl-3 pr-3 mt-3 mb-3"
        @click="submit()"
      >
        Save changes
      </button>

      <button
        type="submit"
        class="btn btn-light pl-3 pr-3 mt-3 mb-3 ml-3"
        @click="$router.push('/collections/')"
      >
        Cancel
      </button>

      <b-form-checkbox
        v-model="form.is_published"
        name="check-button"
        switch
        size="lg"
        class="d-inline-block mt-4 ml-3"
      >
        Published
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";
import ArrayBox from "../components/ArrayBox.vue";
import MultipleFilesUpload from '../components/MultipleFilesUpload.vue';

export default {
  name: "CollectionCreate",
  components: { "array-box": ArrayBox, MultipleFilesUpload },
  data() {
    return {
      id: this.$route.params.id,
      url: "",
      loaded: false,
      search: '',

      colors: [
        "white",
        "yellow",
        "orange",
        "red",
        "green",
        "blue",
        "purple",
        "bronze",
        "silver",
        "gold",
        "ace",
        "exquisite",
        "ultimate",
        "couple",
      ],

      categories: [],
      selectedCategory: null,
      experiences: [],
      experiencesPage: 1,
      experiencesLastPage: 1,

      photoPreviewUrl: null,

      form: {
        title: "",
        slug: "",
        short_description: "",
        description: "",
        need_to_know: "",
        highlights: [],
        video_url: null,
        price: null,
        default_people_count: 1,
        images: null,
        products: [],
        is_published: false,
        color: null,
      },
      errors: [],
    };
  },

  mounted() {
    this.loadCollection();
    this.loadCategories();
  },

  methods: {
    loadCollection() {
      axios
        .get(`${config("BACKEND_ROOT")}/admin/collections/${this.id}`)
        .then((response) => {
          this.form = response.data.data;
          this.form.photos = this.form.images.map(image => "files" + image.url.substring(image.url.lastIndexOf('/')),)

          this.form.price = this.form.price.amount;
          this.loaded = true;
        });
    },

    loadCategories() {
      axios.get(`${config("BACKEND_ROOT")}/categories`).then((response) => {
        this.categories = response.data.data;
      });
    },

    loadExperiences() {
      const searched = this.search;
      let myData = {
        filters: {
          "title": searched
        }
      };

      axios
          .get(config("BACKEND_ROOT") + "/admin/products", { params: myData })
          .then((response) => {
            this.experiences = response.data.data;
            this.experiencesLastPage = response.data.meta.last_page;
          });
    },

    addProduct(
      productId,
      productTitle,
      serviceLevelId,
      serviceLevelTitle,
      thumbnailUrl
    ) {
      if (
        this.form.products.some((el) => el.service_level_id == serviceLevelId)
      ) {
        return alert("You've already added this item!");
      }

      this.form.products.push({
        product_id: productId,
        product_title: productTitle,
        service_level_id: serviceLevelId,
        service_level_title: serviceLevelTitle,
        thumbnail_url: thumbnailUrl,
      });
    },

    removeProduct(serviceLevelId) {
      if (confirm("Do you want to remove this item?")) {
        this.form.products = this.form.products.filter(
          (el) => el.service_level_id != serviceLevelId
        );
      }
    },

    submit() {
      axios
        .put(
          `${config("BACKEND_ROOT")}/admin/collections/${this.id}`,
          this.form
        )
        .then((response) => {
          this.$router.push("/collections");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },
  },
};
</script>

<style scoped>
.experiences__list-group__items {
  max-height: 200px;
  overflow-y: scroll;
}
</style>