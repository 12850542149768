<template>
  <div class="w-100">
    <h1 class="mb-4">Create a landing page</h1>

    <div class="form">
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model="form.slug"
            class="form-control"
            placeholder="slug-of-the-landing-page"
          />
        </div>

        <div class="form-group col-lg-6">
          <input
            v-model="form.title"
            class="form-control"
            placeholder="Title of the landing page"
          />
        </div>
      </div>

      <hr />

      <h5>Choose experiences</h5>
      <div class="row">
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-7">
              <label for="categoryLabel">Search : </label>
              <input
                  v-model="search"
                  class="form-control"
                  placeholder="search product title"
                  @keyup="loadExperiences"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <ul class="list-group experiences__list-group__items">
            <li
              class="list-group-item list-group-item-action"
              v-for="experience of experiences"
              v-bind:key="experience.id"
              @click="
                addExperience(
                  experience.id,
                  experience.title,
                  experience.thumbnail.url,
                  experience.location.city.name,
                  experience.categories
                )
              "
            >
              <strong class="mr-3">{{ experience.title }}</strong>
            </li>
          </ul>

          <div class="selected-products">
            <div
              class="badge badge-success cursor-pointer mr-2"
              v-for="experience of form.sections.experiences"
              v-bind:key="experience.id"
              @click="removeExperience(experience.id)"
            >
              {{ experience.title }}
              x
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h5>Choose collections</h5>
      <div class="row">
        <div class="col-lg-12">
          <ul class="list-group experiences__list-group__items">
            <li
              class="list-group-item list-group-item-action"
              v-for="collection of collections"
              v-bind:key="collection.id"
              @click="addCollection(collection)"
            >
              <strong class="mr-3">{{ collection.title }}</strong>
            </li>
          </ul>

          <div class="selected-products">
            <div
              class="badge badge-success cursor-pointer mr-2"
              v-for="collection of form.sections.collections"
              v-bind:key="collection.id"
              @click="removeCollection(collection.id)"
            >
              {{ collection.title }}
              x
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="form-row">
        <div class="form-group col-lg-12">
          <vue-editor
            v-model="form.description"
            placeholder="Description"
          ></vue-editor>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg-4">
          <label for="formFile" class="form-label"
            >Update the cover image...</label
          >
          <input
            class="form-control"
            type="file"
            id="formFile"
            @change="uploadPhoto"
            ref="upload"
          />
          <div v-if="this.form.photo">
            <i class="fa fa-check"></i> Photo uploaded!
          </div>

          <div id="preview">
            <img
              v-if="photoPreviewUrl"
              :src="photoPreviewUrl"
              class="img-thumbnail mt-2"
              style="width: 150px !important"
            />
          </div>
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <button
        type="submit"
        class="btn btn-success pl-4 pr-4 mt-3 mb-3"
        @click="submit()"
      >
        Create
      </button>
      <b-form-checkbox
        v-model="form.is_published"
        name="check-button"
        switch
        size="lg"
        class="d-inline-block mt-4 ml-3"
      >
        Published
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { config } from "../../helpers";
import { VueEditor } from "vue2-editor";

export default {
  name: "LandingPageCreate",

  components: {
    VueEditor,
  },

  data() {
    return {
      url: "",
      loaded: false,
      search: '',

      categories: [],
      selectedCategory: null,
      experiences: [],
      experiencesPage: 1,
      experiencesLastPage: 1,

      collections: [],

      photoPreviewUrl: null,

      form: {
        slug: "",
        title: "",
        description: "",
        photo: null,
        sections: {
          experiences: [],
          collections: [],
        },
        is_published: false,
      },
      errors: [],
    };
  },

  mounted() {
    this.loadCategories();
    this.loadCollections();
  },

  methods: {
    loadCategories() {
      axios.get(`${config("BACKEND_ROOT")}/categories`).then((response) => {
        this.categories = response.data.data;
        this.loaded = true;
      });
    },

    loadCollections() {
      axios.get(`${config("BACKEND_ROOT")}/collections`).then((response) => {
        this.collections = response.data.data;
      });
    },

    loadExperiences() {
      const searched = this.search;
      let myData = {
        filters: {
          "title": searched
        }
      };

      axios
        .get(config("BACKEND_ROOT") + "/admin/products", { params: myData })
        .then((response) => {
          this.experiences = response.data.data;
          this.experiencesLastPage = response.data.meta.last_page;
        });
    },

    addExperience(id, title, thumbnailUrl, city, categories) {
      if (this.form.sections.experiences.some((el) => el.id == id)) {
        return alert("You've already added this item!");
      }

      this.form.sections.experiences.push({
        id: id,
        title: title,
        thumbnail_url: thumbnailUrl,
        city: city,
        categories: categories.map((category) => {
          return { id: category.id, title: category.title };
        }),
      });
    },

    removeExperience(id) {
      if (confirm("Do you want to remove this item?")) {
        this.form.sections.experiences = this.form.sections.experiences.filter(
          (el) => el.id != id
        );
      }
    },

    addCollection(collection) {
      if (this.form.sections.collections.some((el) => el.id == collection.id)) {
        return alert("You've already added this item!");
      }

      this.form.sections.collections.push({
        id: collection.id,
        title: collection.title,
        thumbnail_url: collection.images[0].url,
        color: collection.color,
        price: collection.price,
      });
    },

    removeCollection(id) {
      if (confirm("Do you want to remove this item?")) {
        this.form.sections.collections = this.form.sections.collections.filter(
          (el) => el.id != id
        );
      }
    },

    submit() {
      axios
        .post(`${config("BACKEND_ROOT")}/admin/landing-pages`, this.form)
        .then((response) => {
          this.$router.push("/landing-pages");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },

    uploadPhoto(e) {
      const file = e.target.files[0];
      this.photoPreviewUrl = URL.createObjectURL(file);

      const type = this.$refs.upload.files[0].type.split('/');

      const formData = new FormData();
      formData.append("file", this.$refs.upload.files[0]);
      formData.append("type", type[0]);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(`${config("BACKEND_ROOT")}/admin/uploads`, formData, { headers })
        .then((res) => {
          this.form.photo = res.data.data.file_name;
        })
        .catch((error) => {
          this.errors = error.response.data.errors.file;
        });
    },
  },
};
</script>

<style scoped>
.experiences__list-group__items {
  max-height: 200px;
  overflow-y: scroll;
}
</style>