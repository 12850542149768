<template>
  <div class="w-100">
    <h1>Users List</h1>

    <div class="row mt-3 mb-1">
      <div class="col-lg-8 mt-2">
        <input
          placeholder="Search by email, name and phone number..."
          v-model="inputFilters.q"
          class="form-control d-inline w-75 mr-2"
          v-on:keyup.enter="filter"
        />
        <button
          class="btn btn-primary d-inline"
          @click="filter"
          style="margin-top: -4px"
        >
          <i class="fas fa-search"></i>
        </button>
        <button
          class="btn btn-secondary ml-2 d-inline"
          @click="exportToCSV"
          v-if="items"
          title="Export to csv"
          style="margin-top: -4px"
        >
          <i class="fas fa-cloud-download-alt"></i>
        </button>
      </div>

      <div class="col-lg-4">
        <button
          class="btn btn-primary float-right"
          @click="showCreateModal()"
          title="Create a new user"
        >
          New user
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Created at</th>
            <th scope="col">Name</th>
            <th scope="col">Country</th>
            <th scope="col">Address</th>
            <th scope="col">Phone number</th>
            <th scope="col">Email</th>
            <th scope="col">Credit</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <router-link
            tag="tr"
            :to="{ name: 'user.show', params: { id: user.id } }"
            v-for="user in items"
            v-bind:key="user.id"
            style="cursor: pointer"
          >
            <td>{{ user.id }}</td>
            <td>{{ formatDate(user.created_at) }}</td>
            <td>{{ user.first_name + " " + user.last_name }}</td>
            <td>{{ user.address ? user.address.country.name : "-" }}</td>
            <td>{{ user.address ? user.address.address : "-" }}</td>
            <td>{{ user.phone_number }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.balance }}</td>
            <td>
              <b-dropdown text="" class="m-md-2" boundary="viewport">
                <b-dropdown-item @click.prevent="loginAsUser(user)">Login as the user</b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="showAddOrDeductCreditModal(user)"
                  >Add/Deduct credit</b-dropdown-item
                >
                <b-dropdown-item @click.prevent="showPurchasesList(user.id)"
                  >View purchase history</b-dropdown-item
                >
                <b-dropdown-item @click.prevent="showWalletHistory(user.id)"
                  >View credit history</b-dropdown-item
                >
              </b-dropdown>
            </td>
          </router-link>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-3">Create a user</div>
        <form action="">
          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="firstnameInput">Firstname</label>
              <input
                v-model="createForm.first_name"
                class="form-control"
                id="firstnameInput"
              />
            </div>
            <div class="form-group col-lg-6">
              <label for="lastnameInput">Lastname</label>
              <input
                v-model="createForm.last_name"
                class="form-control"
                id="lastnameInput"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="emailInput">Email</label>
              <input
                v-model="createForm.email"
                type="email"
                class="form-control"
                id="emailInput"
                required
              />
            </div>
            <div class="form-group col-lg-6">
              <label for="passwordInput">Password</label>
              <input
                v-model="createForm.password"
                type="password"
                class="form-control"
                id="passwordInput"
                required
              />
            </div>
          </div>

          <div class="form-row mt-2">
            <div class="form-group col-lg-4">
              <label for="phoneNumberInput">Phone number</label>
              <input
                v-model="createForm.phone_number"
                class="form-control"
                id="phoneNumberInput"
              />
            </div>
            <div class="form-group col-lg-4">
              <label for="whatsappNumberInput">Whatsapp number</label>
              <input
                v-model="createForm.whatsapp_number"
                class="form-control"
                id="whatsappNumberInput"
              />
            </div>
            <div class="form-group col-lg-4">
              <label for="telegramNumberInput">Telegram number</label>
              <input
                v-model="createForm.telegram_number"
                class="form-control"
                id="telegramNumberInput"
              />
            </div>
          </div>

          <div class="form-row mt-2">
            <div class="form-group col-lg-5">
              <label for="genderSelect">Gender</label>
              <select
                v-model="createForm.gender"
                id="genderSelect"
                class="form-control"
              >
                <option value="1">Man</option>
                <option value="2">Woman</option>
                <option value="3">Unknown</option>
              </select>
            </div>
            <div class="form-group col-lg-7">
              <label for="birthdateInput">Birthday</label>
              <input
                v-model="createForm.birth_date"
                type="date"
                class="form-control"
                id="birthdateInput"
                placeholder="yyyy-mm-dd"
              />
            </div>
          </div>

          <hr />
          <h6>User address</h6>

          <div class="form-row mt-3">
            <div class="form-group col-lg-6">
              <country-search-box @selected="(id) => createForm.country_id = id"></country-search-box>
            </div>
            <div class="form-group col-lg-6">
              <city-search-box @selected="(id) => createForm.city_id = id"></city-search-box>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-8">
              <label for="addressInput">Address</label>
              <input
                v-model="createForm.address"
                class="form-control"
                id="addressInput"
              />
            </div>
            <div class="form-group col-lg-4">
              <label for="postcodeInput">Postcode</label>
              <input
                v-model="createForm.post_code"
                class="form-control"
                id="postcodeInput"
              />
            </div>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button
            class="btn btn-primary mt-3 w-100"
            @click.prevent="createUser"
          >
            Create
          </button>
        </form>
      </div>
    </modal>

    <modal name="change-credit-modal" height="auto">
      <div class="p-4">
        <div>
          <div class="h3 mb-3 d-inline-block">Customer Credits Management</div>
          <button
            @click="$modal.hide('change-credit-modal')"
            class="modal-close-button"
          >
            ❌
          </button>
        </div>
        <div>
          Available credit is:
          <span v-text="changeCreditForm.currentBalance"></span>
        </div>

        <div class="mt-4">
          <b-card no-body>
            <b-tabs pills card>
              <b-tab title="Add" active>
                <form action="">
                  <label for="addCreditInput">Amount to add:</label>
                  <input
                    v-model="changeCreditForm.amount"
                    class="form-control"
                    id="addCreditInput"
                    type="number"
                  />
                  <button
                    class="btn btn-success mt-3 w-100"
                    @click.prevent="changeCredit('deposit')"
                  >
                    Submit
                  </button>
                </form>
              </b-tab>
              <b-tab title="Deduct">
                <form action="">
                  <label for="deductCreditInput">Amount to deduct:</label>
                  <input
                    v-model="changeCreditForm.amount"
                    class="form-control"
                    id="deductCreditInput"
                    type="number"
                  />
                  <button
                    class="btn btn-success mt-3 w-100"
                    @click.prevent="changeCredit('withdraw')"
                  >
                    Submit
                  </button>
                </form>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { config } from "../../helpers";
import SearchBox from "../../components/SearchBox.vue";
import CountrySearchBox from "../../components/CountrySearchBox.vue";
import CitySearchBox from "../../components/CitySearchBox.vue";
import {router} from "@/router";

export default {
  name: "Users",
  components: {
    "search-box": SearchBox,
    "country-search-box": CountrySearchBox,
    "city-search-box": CitySearchBox,
  },
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: null,
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      totalItems: 1,
      perPage: 1,

      createForm: {},

      changeCreditForm: {
        id: 0,
        currentBalance: 0.0,
        amount: 0.0,
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/users";

    if (this.$route.params?.q) {
      this.inputFilters.q = this.$route.params.q;
      this.filter();
    }

    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        myData = { ...myData, q: this.filters.q };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      this.filters = filters;

      this.loadData();
    },

    createUser() {
      axios
        .post(this.url, this.createForm)
        .then((response) => {
          this.createForm = {};
          alert("User created successfully!");
          this.$modal.hide("create-modal");
        })
        .catch((error) => {
          alert(Object.values(error.response.data.errors).flat()[0] ?? error.response.data?.meta?.message);
        });
    },

    exportToCSV() {
      let myData = {};

      if (this.isFiltered) {
        myData = { q: this.filters.q };
      }

      axios.get(this.url + "/export", { params: myData }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },

    changeCredit(action) {
      axios
        .post(`${this.url}/${this.changeCreditForm.id}/wallet/${action}`, {
          amount: this.changeCreditForm.amount,
        })
        .then((response) => {
          return this.showWalletHistory(this.changeCreditForm.id);
        })
        .catch((error) => {
          alert(
            Object.values(
              error.response?.data?.errors ?? ["Something went wrong!"]
            ).flat()[0]
          );
        });
    },

    showPurchasesList(userId) {
      this.$router.push(`/users/${userId}/purchases`);
    },

    showWalletHistory(userId) {
      this.$router.push(`/users/${userId}/wallet-history`);
    },

    showCreateModal() {
      this.$modal.show("create-modal");
    },

    loginAsUser(user) {
      axios
        .post(`${config("BACKEND_ROOT")}/admin/users/${user.id}/login`)
        .then((response) => {
          const token = response.data.access_token;
          const refresh = response.data.refresh_token;

          window.open(`${config('FRONTEND_ROOT')}/auth/autologin?token=${token}&refresh=${refresh}&to=/dashboard`, '_blank').focus();
        });
    },

    editUser(user) {
      router.push(
          `/users/${user.id}/edit`
      )
    },

    showAddOrDeductCreditModal(user) {
      this.changeCreditForm.id = user.id;
      this.changeCreditForm.currentBalance = user.balance;
      this.changeCreditForm.amount = 0.0;
      this.$modal.show("change-credit-modal");
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },

  watch: {
    page: function (val) {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>