<template>
  <div class="w-100 pl-1">
    <h1>Tags Management</h1>

    <div id="items" class="row mt-3 pl-2">
      <div class="item col-lg-3 add-item" @click="showCreateModal">
        <div class="text-center">
          <i class="fa fa-plus"></i><br />
          Create a tag
        </div>
      </div>

      <div
        class="item col-lg-3"
        v-for="item in items"
        v-bind:key="item.id"
        :style="getPhotoUrl(item)"
        @click="showUpdateModal(item)"
      >
        <div class="item-overlay">
          {{ item.title }}<br />
          <i class="fa fa-hashtag"></i> {{ item.slug }}
        </div>
      </div>
    </div>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-3">Create a tag</div>
        <form action="">
          <select
            v-model="createForm.type"
            class="form-control mb-3"
            :required="required"
          >
            <option value="event">Event</option>
            <option value="occasion">Occasion</option>
            <option value="recipient">Recipient</option>
          </select>
          <input
            v-model="createForm.title"
            type="text"
            class="form-control"
            placeholder="Title"
            required
          />
          <input
            v-model="createForm.slug"
            type="text"
            class="form-control mt-3 mb-3"
            placeholder="some-slug-here"
            required
          />
          <textarea
            v-model="createForm.description"
            class="form-control"
            placeholder="Description"
          ></textarea>
          <div class="mb-2 mt-3">
            <label for="formFile" class="form-label">Select a photo...</label>
            <input
              class="form-control"
              type="file"
              id="formFile"
              @change="createFormUpload"
              ref="createFormUpload"
            />
          </div>
          <div v-if="this.createForm.photo">
            <i class="fa fa-check"></i> Photo uploaded!
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="createTag">
            Create
          </button>
        </form>
      </div>
    </modal>

    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-3">Update a tag</div>
        <form action="">
          <select
            v-model="updateForm.type"
            class="form-control mb-3"
            :required="required"
          >
            <option value="event">Event</option>
            <option value="occasion">Occasion</option>
            <option value="recipient">Recipient</option>
          </select>
          <input
            v-model="updateForm.title"
            type="text"
            class="form-control"
            placeholder="Title"
            required
          />
          <input
            v-model="updateForm.slug"
            type="text"
            class="form-control mt-3 mb-3"
            placeholder="some-slug-here"
            required
          />
          <textarea
            v-model="updateForm.description"
            class="form-control"
            placeholder="Description"
          ></textarea>
          <div class="mb-2 mt-3">
            <label for="formFile" class="form-label">Select a photo...</label>
            <input
              class="form-control"
              type="file"
              id="formFile"
              @change="updateFormUpload"
              ref="updateFormUpload"
            />
          </div>
          <div v-if="this.updateForm.photo">
            <i class="fa fa-check"></i> Photo uploaded!
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in updateForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="updateTag">
            Update
          </button>
          <button class="btn btn-danger mt-2 w-100" @click="deleteTag">
            Delete
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  name: "Tags",
  data() {
    return {
      url: "",
      items: [],
      createForm: {
        type: "",
        title: "",
        slug: "",
        description: "",
        photo: "",
        errors: [],
      },
      updateForm: {
        id: null,
        type: "",
        title: "",
        slug: "",
        description: "",
        photo: "",
        errors: [],
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/tags";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(this.url).then((response) => {
        this.items = response.data.data;
      });
    },

    createTag(event) {
      event.preventDefault();
      axios
        .post(this.url, {
          type: this.createForm.type,
          title: this.createForm.title,
          slug: this.createForm.slug,
          photo: this.createForm.photo,
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("create-modal");
        })
        .catch((error) => {
          this.createForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    updateTag(event) {
      event.preventDefault();
      axios
        .put(this.url + "/" + this.updateForm.id, {
          type: this.updateForm.type,
          title: this.updateForm.title,
          slug: this.updateForm.slug,
          description: this.updateForm.description,
          photo: this.updateForm.photo,
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("update-modal");
        })
        .catch((error) => {
          this.updateForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    deleteTag(event) {
      event.preventDefault();
      if (confirm("Do you want to delete this tag?")) {
        axios.delete(this.url + "/" + this.updateForm.id).then((response) => {
          location.reload();
        });
      }
    },

    showCreateModal() {
      this.$modal.show("create-modal");
    },

    showUpdateModal(item) {
      this.updateForm = {
        id: null,
        type: null,
        title: "",
        slug: "",
        description: "",
        photo: "",
        errors: [],
      };
      this.updateForm.id = item.id;
      this.updateForm.type = item.type;
      this.updateForm.title = item.title;
      this.updateForm.slug = item.slug;
      this.updateForm.description = item.description;
      this.$modal.show("update-modal");
    },

    createFormUpload() {
      const formData = new FormData();
      formData.append("file", this.$refs.createFormUpload.files[0]);
      formData.append("type", this.$refs.createFormUpload.files[0].type.split('/')[0]);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.createForm.photo = res.data.data.file_name;
        })
        .catch((error) => {
          this.createForm.errors = error.response.data.errors.file;
        });
    },

    updateFormUpload() {
      const formData = new FormData();
      formData.append("file", this.$refs.updateFormUpload.files[0]);
      formData.append("type", this.$refs.updateFormUpload.files[0].type.split('/')[0]);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(config("BACKEND_ROOT") + "/admin/uploads", formData, { headers })
        .then((res) => {
          this.updateForm.photo = res.data.data.file_name;
        })
        .catch((error) => {
          this.updateForm.errors = error.response.data.errors.file;
        });
    },

    getPhotoUrl(item) {
      if (!item.photo) {
        return "";
      }

      return `background:url("${item.photo.url}")`;
    },
  },
};
</script>

<style scoped>
.item {
  background-size: cover !important;
  color: #fff;
  min-height: 85px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 5px !important;
  overflow: hidden;
  cursor: pointer;
}
.item-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
  padding: 15px 20px;
  transition: 0.3s !important;
}
.item-overlay:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.add-item {
  color: #666;
  border: 2px dashed #999;
  padding-top: 17px;
  transition: 0.3s !important;
}
.add-item:hover {
  color: #f42f4b;
  border-color: #f42f4b;
}
</style>